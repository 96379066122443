import axios from "axios";
import conf from "../settings/conf";

const axiosCall = async({ method, path, body }) => {
  try {
    const baseUrl = "https://api.onl.taptag.co.kr"
    let res = await axios({
      method,
      url: baseUrl + path,
      data: body,
      headers: { 'x-taptag-key': conf.apiKey }
    });

    if(res.status !== 200) throw Error('Response Error');
    
    return res.data;
  }catch(e) {
    return { err: { message: e.message } };
  }
}

export const oasisposProxy = async({ apiName, body }) => {
  //
  // apiNames:
  // "allTable": "/allTable", // 모든 테이블 정보
  // "inqTable": "/inqTable", // 특정 테이블 정보
  // "callManager": "/callManager", // 직원 콜
  // "callService": "/callService", // 서비스 콜 - 필요한가
  // "orderInq": "/orderInq", // 주문 확인
  // "orderInput": "/orderInput", // 주문
  // "menuGroup": "/menuGroup", // 메뉴그룹 리스트
  // "setMenu": "/setMenu", // 세트메뉴 리스트
  // "menu": "/menu",
  // 
  let path = `/pos/oasispos?apiName=${apiName}`;
  // if(conf.debug) path += '&mode=debug';
  path += '&mode=debug';
  
  return await axiosCall({ method: 'POST', path, body });
};

export function getReqTime_oasispos() {
  const now = new Date();
  const yr = now.getFullYear();
  const mon = now.getMonth();
  const da = now.getDate();
  const hr = now.getHours();
  const min = now.getMinutes();
  const sec = now.getSeconds();

  const fullTime = yr.toString()
    + "-" + (mon + 1).toString().padStart(2, '0')
    + "-" + da.toString().padStart(2, '0')
    + " " + hr.toString().padStart(2, '0')
    + ":" + min.toString().padStart(2, '0')
    + ":" + sec.toString().padStart(2, '0')
  
    return fullTime;
}
const posMap = {
  OKPOS(props) {
    return okmapping(props);
  },
  ONLPOS(props) {
    return onlmapping(props);
  },
}

const okmapping = (props) => {
  const { rdxInfo, choiceGrps, choiceItems } = props;
  
  const grps = choiceGrps.map((grp) => {
    return {
      ...grp,
      backupName: grp.SDA_CLS_NM || grp.SDS_CLS_NM,
      groupCode: grp.SDA_CLS_CD || grp.SDS_CLS_CD,
      menuCode: grp.SDA_CLS_CD || grp.SDS_CLS_CD,
      groupName: grp.SDA_CLS_NM || grp.SDS_CLS_NM,
      itemName: grp.SDA_CLS_NM || grp.SDS_CLS_NM,
      itemName2: grp.SDA_CLS_NM || grp.SDS_CLS_NM,
      itemCode: grp.SDA_CLS_CD || grp.SDS_CLS_CD,
      menuType: '선택',
      menuType2: 'CHOICE',
      selected: 0,
    }
  });
  const items = choiceItems.map((item) => {
    return {
      ...item,
      backupName: item.SDA_NM || item.SDS_NM,
      created: item.INS_DT,
      groupCode: item.SDA_CLS_CD || item.SDS_CLS_CD,
      MenuCode: item.SDA_CLS_CD || item.SDS_CLS_CD,
      itemCode: item.SDA_CD || item.PROD_CD,
      itemName: item.SDA_NM || item.SDS_NM,
      itemName2: item.SDA_NM || item.SDS_NM,
      menuType: '선택',
      menuType2: 'CHOICE',
      price: (item.SDS_PROD_UPRC)?.split('.')[0] || '0',
    }
  });
  
  const chItems = items.map((item) => {
    const temp = rdxInfo.itemList.find((it) => it.itemCode === item.itemCode);
    
    return item = {
      ...item,
      itemName2: temp?.itemName2 ? temp.itemName2 : item.itemName2,
      nameLang: temp?.nameLang,
      isSoldout: temp?.isSoldout,
    }
  });

  return { choiceGrps: grps, choiceItems: chItems }
}
const onlmapping = (props) => {
  const { choiceGrps, choiceItems } = props;
  
  const grps = choiceGrps?.map((grp) => {
    return {
      ...grp,
      menuCode: grp.classCode,
      backupName: grp.name,
      groupCode: grp.classCode,
      groupName: grp.name,
      itemName: grp.name,
      itemName2: grp.name,
      menuType: '선택',
      menuType2: 'CHOICE',
      selected: 0,
    }
  });
  let items = choiceItems.map((item) => {
    return {
      ...item,
      // backupName: item.name,
      // itemCode: item.itemCode,
      // itemName: item.name,
      // itemName2: item.name,
      menuType: '선택',
      menuType2: 'CHOICE',
      optionPrice: item.optionPrice.toString(),
      price: item.price.toString(),
    }
  });
  
  choiceGrps.map((grp) => {
    grp.itemList.map((gItem) => {
      items.map((item) => {
        if(item.itemCode === gItem) 
          item.MenuCode = grp.classCode;
      });
    });
  });
  
  return { choiceGrps: grps, choiceItems: items }
}

const excutemapping = (props) => {
  return posMap[props.posName](props);
}

const okOptionsMapping = (props) => {
  return excutemapping(props);
}

export default okOptionsMapping;
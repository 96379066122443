import React from 'react';

const CallLink = ({ phoneNumber, color }) => {
  return (
    <div>
      
      <a 
        href={`tel:${phoneNumber}`}
        style={{color: color}}
      >
      문의전화 : {phoneNumber}
      </a>
    </div>
  );
}

export default CallLink;
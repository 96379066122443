import CustomDialog from "../../../components/CustomDialog";

const Notice = (props) => {
  const { rdxInfo, visible } = props;

  
  
  const onOk = () => visible(false);
  
  return(
    <CustomDialog
      visible={true}
      sysMsg={rdxInfo.systemMsg}
      title={rdxInfo.systemMsg.ui.notice}
      onOk={onOk}
    >
      <div>{rdxInfo.shopInfo.noticeOptions.notice}</div>
    </CustomDialog>
  )
}

export default Notice;
import React, { useEffect, useRef } from "react";
import { makeThumbnailPath }  from "../../../lib/utils";

import BnH from "../../../components/BNH";
import Price      from "../../../components/Price";
import Image      from "../../../components/Image";
import Soldout    from '../../../assets/images/soldout.png';
import constants  from "../../../settings/constants";

import './ItemSection.css';

const MenuItem = (props) => {
  const { item, rdxInfo } = props;
  const { setOpenDetailPage, setSelectedItem } = props;
  
  const isVertical = rdxInfo.shopInfo.userAppUiOptions?.groupBarPosition === 'left';
  const clientWidth = window.innerWidth;
  const imageWidth = isVertical && (clientWidth < 370) ? constants.menuSizeVertical : constants.menuSize;
  const imgUrl = makeThumbnailPath(item.image, item.shopId);
  
  const onItemClick = () => {
    if(item.isSoldout)
      return;

    setSelectedItem(item);
    setOpenDetailPage(true);
  }

  return(
    <div className={item.isSoldout ? 'SoldoutItem' : "MenuItem"} onClick={onItemClick}>
      <div className="item-info">
        <div className="item-name">
          <BnH item={item} />
          {item.itemName2}
        </div>
        <div className="item-desc">{item.description}</div>
        <Price price={item.price} lang={rdxInfo.systemMsg} />
      </div>
      <div className="prod-image">
        { item.isSoldout && <img className="soldout" src={Soldout} width={constants.menuSize} /> }
        { item.image     && <Image src={imgUrl} width={imageWidth} type='menu'/> }
      </div>
    </div>
  )
}

export default MenuItem;
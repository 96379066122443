import { supportedLangs } from "../../../settings/langs";
import { unionposProxy2 } from "../../../lib/ec2-api-lib";

import { bnh, sortTableList, usePosLang, usingPosName }   from "../../../lib/utils";
import { sorting, checkServiceTime, grpCheckServiceTime } from '../../../lib/utils';

import { isEmptyObject, makeOptionInfo, parseIf, setName, setSortInfo } from "../../../lib/utils";

import makeOptionItems from "./make-option-items";

const posMap = {
  ItemInfo(props) {
    const res = itemInfo(props);
    return res;
  },
  ItemList(props) {
    const res = itemList(props);
    return res;
  },
  MenuList(props) {
    const res = menuList(props);
    return res;
  },
  Options(props) {
    const res = makeOption(props);
    return res;
  },
  EssExt(props) {
    const res = makeEssExt(props);
    return res;
  },
  CartItems(props) {
    const res = makeCartItems(props);
    return res;
  },
  OrderList(props) {
    const res = orderList(props);
    return res;
  }
}

const itemInfo = (props) => {
  const { itemInfo, urlInfo } = props;

  const keysToParse = [
    'GrpList', 'TableList', 'SetMenuList', 'menuSettings', 'ChoiceMenuList',
    'tableCallList', 'optionItemList'
  ];

  /** parsing datas */
  keysToParse.forEach((key) => {
    itemInfo[key] = parseIf(itemInfo[key]);
  });

  /** check service time */
  grpCheckServiceTime(itemInfo.GrpList);

  /** make DisplayGrpList */
  itemInfo.DisplayGrpList = itemInfo.GrpList.filter(grp => 
    !grp.hidden && (grp.isService === true || grp.isService === undefined)
  )

  /** Sorting DisplayGrpList */
  itemInfo.DisplayGrpList = sorting(itemInfo.DisplayGrpList);

  /** pos 메뉴명 사용여부 결정 */
  usingPosName(itemInfo);

  itemInfo.GrpList.map((grp) => {
    supportedLangs.forEach((lang) => usePosLang(grp, lang))
  });

  /** TableInfo 및 TableList 설정 */
  const result = itemInfo.TableList.filter((table) => 
    table.TableCode.toString() === Number(urlInfo.tableNo).toString()
  );
  itemInfo.TableInfo = result[0];
  itemInfo.TableList = sortTableList(itemInfo.TableList);
  
  return itemInfo;
}

const itemList = (props) => {
  const { itemList, itemInfo } = props;
  const keyToParse = [
    'nameLang', 'serviceTime', 'descriptionLang', 'posNameLang', 'posDescriptionLang'
  ];

  itemList.map((item) => {
    keyToParse.forEach(key => {
      item[key] = parseIf(item[key])
  });
    
    // item posname 설정
    setName(itemInfo.menuSettings?.usePosMenuFirst, item);
    
    // pos에 저장된 언어들 사용 설정. 유니온포스만 적용
    supportedLangs.forEach(lang => usePosLang(item, lang));

    if(item.serviceTime)
      item.isService = checkServiceTime(item.serviceTime);
    
    /** SortInfo */
    setSortInfo(itemInfo.GrpList, item, itemInfo.optionName.toUpperCase());
  });
  
  return itemList;
}

const menuList = (props) => {
  const { itemInfo, itemList } = props;
  let choice = [];
  
  itemInfo.GrpList.forEach(grp => {
    grp.menus = itemList?.filter(item => item.groupCode?.includes(grp.GrpCode));
    grp.menus = sorting(grp.menus);
    grp.menus = bnh(grp.menus);
  });

  itemInfo.ChoiceMenuList.forEach((choiceItem) => {
    itemList.forEach(item => {
      if(choiceItem.ItemCode === item.itemCode) {
        const _item = { ...item, MenuCode: choiceItem.MenuCode }
        choice.push(_item);
      }
    });
  });
  itemInfo.ChoiceItemList = choice;
}

const makeOption = (props) => {
  const { item, rdxInfo } = props;
  const choiceGrps = [];
  const choiceItems = [];
  const arTemp = [];

  if(item.menuType2 !== 'DYNAMIC')
    return { choiceGrps: [], choiceItems: [] }
  
  const setMenus = 
    rdxInfo.itemInfo.SetMenuList.filter(setmenu => setmenu.MenuCode === item.menuCode);
  if(setMenus.length) {
    setMenus.forEach(menu => {
      rdxInfo.itemList.forEach(item => {
        if(item.itemCode === menu.ItemCode)
          choiceGrps.push(item);
      })
    })
  }

  let temp;
  choiceGrps.forEach(grp => {
    rdxInfo.itemInfo.ChoiceItemList.forEach(item => {
      if(item.MenuCode === grp.menuCode) {
        temp = {};
        rdxInfo.itemInfo.ChoiceMenuList.forEach(chItem => {
          if((item.itemCode === chItem.ItemCode) && (item.MenuCode === chItem.MenuCode) && !item.removed && !item.isSoldout) {
            temp = { ...item, choiceInfo: chItem }
          }
        });
        !isEmptyObject(temp) && choiceItems.push(temp);
      }
    })
  });
  choiceGrps.forEach(grp => {
    const temp = rdxInfo.itemInfo.ChoiceMenuList.filter(item => item.MenuCode === grp.menuCode);
    
    let setCnt = 0;
    temp.forEach(item => setCnt = item.SetCnt + setCnt);
    setCnt = setCnt / temp.length;

    const tempGrp = { ...grp, SetCnt: setCnt, selected: 0, isSame: true }
    arTemp.push(tempGrp);
  });

  return { choiceGrps: arTemp, choiceItems }
}

const makeEssExt = (props) => {
  const { ess, ext, choiceItems, posName } = props;

  makeOptionItems(ess, choiceItems, posName);
  makeOptionItems(ext, choiceItems, posName);
}

const makeCartItems = (props) => {
  const { item, checkedList } = props;
  const optionInfo = makeOptionInfo(checkedList);
  const fullName = item.itemName2 + '#onl' + optionInfo.fullName;
  const options = Array.from(checkedList);

  const _item = {
    defaultItemName: item.itemName,
    itemName: fullName,
    itemCode: item.itemCode,
    desc: item.description,
    best: item.isBest,
    hot: item.isHot,
    new: item.isNew,
    grpSortOrder: item.grpSortOrder,
    itemGroup: item.itemGroup,
    menuCode: item.menuCode,
    menuType: item.menuType,
    menuType2: item.menuType2,
    soldOut: item.soldOut,
    image: item.image,
    price: parseInt(item.price) + (optionInfo.optionPrice),
    count: props.itemCount,
    optionPrice: optionInfo.optionPrice,
    payAmount: parseInt(item.price) + (optionInfo.optionPrice),
    isReceived: false,
    dp: false,
    sortInfo: item.sortInfo,
    options,
  }
  checkedList.clear();

  return _item;
}

const orderList = async (props) => {
  const { rdxInfo } = props;
  const { setLoading } = props;

  try {
    setLoading(true);

    const res = await unionposProxy2({
      apiName: 'tableOrderList',
      body: {
        StoreCode: rdxInfo.shopInfo.storeCode,
        TableCode: rdxInfo.itemInfo.TableInfo.TableCode,
      }
    });
    if(!res.status) throw new Error('order list call error!');
    
    let mainMenu = res.result.DATA.OrderList;
    let option = res.result.DATA.OrderSetList;
    let list = [];

    mainMenu.sort((a, b) => a.SerNo - b.SerNo);
    
    option = option.map(item => {
      return item = { ...item, SDA_CD: item.MenuCode, TotalAmt: item.Price }
    });
    mainMenu.map(item => {
      const temp = option.filter(opt => item.MenuCode === opt.MenuCode);
      item.options = temp;
      list = [ ...list, item, ...temp ];
    });
    option.forEach(opt => {
      const matched = mainMenu.find(item => item.SerNo === opt.SerNo);
      if(matched) {
        opt.Qty *= matched.Qty;
        opt.TotalAmt *= matched.Qty;
      }
    });
    mainMenu.forEach(item => {
      let sum = 0;
      item.options.forEach(opt => {
        sum = sum + (opt.Price * item.Qty);
      });
      item.TotalAmt = item.TotalAmt - sum;
    });

    return {
      orders: mainMenu,
      status: res.result.CODE ==='S00000',
      amount: res.result.DATA.OrderSum.TotalAmt || 0
    }
  }catch(e) {
    console.log('call order list error: ', e.message);
  }finally { setLoading(false) }
}

export default function excuteUnion(props) {
  return posMap[props.type](props);
}
import constants from "../../settings/constants";

export const makeTableName = (sysMsg, tableInfo) => {
  let tbName;
  
  const isInclude = constants.tableNick.some(nick => tableInfo?.TableName?.includes(nick));

  if(isInclude)
    tbName = tableInfo.TableName;
  else {
    tbName = tableInfo?.TableName ? tableInfo.TableName : '';
    if(sysMsg.langName === '한')
      tbName = tbName + sysMsg.ui.tableNick;
    else
      tbName = sysMsg.ui.tableNick + tbName;
  }

  return tbName;
}
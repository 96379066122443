import { useEffect } from "react";

import { Form } from "react-bootstrap";

import Price from "../../../components/Price";
import Counter from '../../../components/Counter';

import './BodySection.css';

const Extra = (props) => {
  const { 
    currentGroup, optionItems, rdxInfo, checkOption, 
    choiceGroups, optionCount, checkedList,
  } = props;
  const { 
    setSame, setCheckOption, setOptionCount, setCheckedList,
    setSelectedOptionCount,
  } = props;
  const checkboxStyle = {
    width: currentGroup.SetCnt < 2 ? '80%' : '60%',
    marginRight: currentGroup.SetCnt < 2 ? '0px' : '3px'
  };
  const counterStyle = {
    width: currentGroup.SetCnt < 2 ? '1%' : '18%'
  }

  useEffect(() => {
    let setcntSum = 0;
    let selectedSum = 0;

    choiceGroups.forEach(grp => {
      if(grp.SetCnt > 1) {
        setcntSum = setcntSum + grp.SetCnt;
        selectedSum = selectedSum + grp.selected;
      }
    });
    
    if(setcntSum === selectedSum)
      setSame(false);
    else
      setSame(true);
  }, [currentGroup.selected]);

  useEffect(() => {
    let sum = 0;
    if(currentGroup.SetCnt > 0) {
      optionItems.forEach(item => sum = sum + item.Qty);
      currentGroup.selected = sum;
      setSelectedOptionCount(sum);
    }
  }, [optionCount]);

  useEffect(() => {
    optionItems.forEach(item => {
      if(item?.choiceInfo?.Qty) {
        setSelectedOptionCount(prev => prev + 1);
        checkedList.add(item);
        item.checked = true;
        item.Qty = 1;
        currentGroup.selected = currentGroup.selected + 1;
      }
    })
    setCheckedList(checkedList);
  }, []);

  const onChecked = (item, e) => {
    const newCheckedList = new Set(checkedList);

    if(((currentGroup.selected >= currentGroup.SetCnt) && currentGroup.SetCnt >= 1) && e.target.checked)
      return;

    if(e.target.checked) {
      setSelectedOptionCount(prev => prev + 1);
      newCheckedList.add(item);
      item.checked = true;
      item.Qty = 1;
      currentGroup.selected = currentGroup.selected + 1;
    }else if(!(e.target.checked) && newCheckedList.has(item)) {
      const qty = item.Qty;

      currentGroup.selected = currentGroup.selected - qty;
      setSelectedOptionCount(prev => prev - qty);
      setSame(true);
      newCheckedList.delete(item);
      item.checked = false;
      item.Qty = 0;
    }
    setCheckedList(newCheckedList);
    setCheckOption(!checkOption);
  }

  const optionCounter = (newQty, item) => {
    const newCheckedList = new Set(checkedList);

    newCheckedList.delete(item);
    item.Qty = newQty;
    newCheckedList.add(item);
    setCheckedList(newCheckedList);
    setOptionCount(prev => prev + 1);
  }

  return(
    <div className="Extra">
      <Form>
        { optionItems.map((item, idx) => {
          const price = item?.choiceInfo?.Price !== undefined ? item.choiceInfo.Price : item.price;

          return(
            <div key={idx}>
              { !item.isSoldout &&
                <div className="extra-section" >
                  <div className="checkbox-section" style={checkboxStyle}>
                    <Form.Check
                      className={checkedList.has(item) ? 'custom-checkbox':''}
                      type="checkbox"
                      name="extra"
                      id={`check-${item.itemName2}`}
                      label={item.itemName2}
                      value={item.itemName2}
                      checked={checkedList.has(item)}
                      onChange={(e) => onChecked(item, e)}
                    />
                  </div>
                  <div className="counter-section" style={counterStyle}>
                    { (currentGroup.SetCnt > 1 && item.checked) &&
                      <Counter
                        count={item.Qty}
                        group={currentGroup}
                        checked={checkOption}
                        setCount={(newQty) => optionCounter(newQty, item)}
                      />
                    }
                  </div>
                  <div className="price-section">
                    <Price price={price} lang={rdxInfo.systemMsg} type='option' />
                  </div>
                </div>
              }
            </div>
          )
        })}
      </Form>
    </div>
  )
}

export default Extra;
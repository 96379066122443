import './IsEssential.css';

const IsEssential = ({ ess, setCnt, sysMsg }) => {
  return(
    <div className='IsEssential'>
      { ess
        ? <div className="common essential">{sysMsg.ui.essential}</div>
        : <div>
            { setCnt > 1
              ? <div className="common essential"> 
                  {sysMsg.ui.totalCnt1}{setCnt}{sysMsg.ui.totalCnt2}
                </div>
              : <div className='common extra'>{sysMsg.ui.extra}</div>
            }
          </div>
      }
    </div>
  )
}

export default IsEssential;
import React, { useEffect, useState } from "react";

import Image from '../../../components/Image';
import MenuSection from '../MenuSection';
import TitleSection from "../TitleSection";
import IconSection from "../IconSection";

import Loader from "../../../components/Loader";
import MobileCart from "../../Cart/Mobile/MobileCart";
import BottomButton from "../../../components/BottomButton";
import CustomDialog from "../../../components/CustomDialog";
import MobileDetail from "../../Detail/Mobile/MobileDetail";
import GetPhoneNumber from "../GetPhoneNumber";

import './MobileMenu.css';

const MobileMenu = React.memo((props) => {
  const { rdxInfo, openDetailPage, openCartPage } = props;
  
  const [isNotice, setNotice] = useState(false);
  const [phoneNum, setPhoneNum] = useState('');
  const [imgHeight, setImgHeight] = useState();
  const [orderMessage, setOrderMessage] = useState({title:'', body: ''});

  const [isPhone, setIsPhone] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [orderResult, setOrderResult] = useState(false);
  
  const shopImage = rdxInfo.shopInfo.image;
  const phoneProps = { 
    ...props, isPhone, phoneNum,
    setIsPhone, setPhoneNum, setLoading, setOrderMessage, setOrderResult,
  }
  
  useEffect(() => {
    setNotice(rdxInfo.shopInfo.noticeOptions?.useNotice);
  }, []);
  if(isLoading){
    return <Loader />
  }
  return(
    <div className="Mobile">
      <Header image={shopImage} height={setImgHeight} />
      <Body { ...props } height={imgHeight} />
      <Footer { ...phoneProps } />
      { isPhone && <GetPhoneNumber { ...phoneProps} /> }
      { openDetailPage && <MobileDetail { ...props } /> }
      { openCartPage && <MobileCart { ...phoneProps } /> }
      <CustomDialog
        visible={isNotice}
        sysMsg={rdxInfo.systemMsg}
        title={rdxInfo.systemMsg.ui.notice}
        onOk={()=>setNotice(false)}
      >
        <div>{rdxInfo.shopInfo.noticeOptions?.notice}</div>
      </CustomDialog>
      <CustomDialog
        visible={orderResult}
        sysMsg={rdxInfo.systemMsg}
        title={orderMessage.title}
        onOk={()=>setOrderResult(false)}
      >
        <div>{orderMessage.body}</div>
      </CustomDialog>
    </div>
  )
});

export default MobileMenu;

function Header ({image, height}) {
  return(
    <div className="shop-image">
      <Image src={image} width='100%' setHeight={height} />
    </div>
  )
}

function Body (props) {
  const { height, rdxInfo, cartItems } = props;
  const bodyClass = cartItems.length ? 'menu-list with-cart' : 'menu-list';
  return(
    <div 
      id='menus' 
      className={bodyClass}
      style={{top: height}}
    >
      <TitleSection { ...props } device='Mobile' />
      <IconSection rdxInfo={rdxInfo} />
      <MenuSection { ...props } />
    </div>
  )
}

function Footer(props) {
  const { rdxInfo, cartItems, phoneNum } = props;
  const { setOpenCartPage, setIsPhone } = props;
  
  const onCartClick = () => {
    if(rdxInfo.shopInfo.orderNotifyOptions?.toUser && 
        !rdxInfo.shopInfo.payOptions?.isPayable) {
          phoneNum ? setOpenCartPage(true) : setIsPhone(true);
    }else {
      setOpenCartPage(true);
    }
  }
  return(
    <div className="cart-button-container">
    { cartItems.length
      ? <BottomButton
          title='cart'
          uiText={rdxInfo.systemMsg}
          click={onCartClick}
        />
      : null
    }
  </div>
  )
}
import excuteOk     from "./ok";
import excuteOasis  from './oasis';
import excuteUnion  from "./union";

const posMap = {
  UNIONPOS(props) {
    const res = excuteUnion(props);
    return res;
  },
  OKPOS(props) {
    const res = excuteOk(props);
    return res;
  },
  OASIS_POS(props) {
    const res = excuteOasis(props);
    return res;
  }
}

const excute = (props) => {
  return posMap[props.posName](props);
}

export default excute;
import { getReqTime_oasispos, oasisposProxy } from "../../../lib/oasis-api-lib";
import { checkServiceTime, grpCheckServiceTime, parseIf, setName, bnh, makeOptionInfo, getIp } from "../../../lib/utils";
import { setSortInfo, sorting, sortTableList, usePosLang, usingPosName } from "../../../lib/utils";
import { supportedLangs } from "../../../settings/langs";
import makeOptionItems from "./make-option-items";

const posMap = {
  ItemInfo(props) {
    const res = itemInfo(props);
    return res;
  },
  ItemList(props) {
    const res = itemList(props);
    return res;
  },
  MenuList(props) {
    const res = menuList(props);
    return res;
  },
  Options(props) {
    const res = makeOption(props);
    return res;
  },
  EssExt(props) {
    const res = makeEssExt(props);
    return res;
  },
  CartItems(props) {
    const res = makeCartItems(props);
    return res;
  },
  OrderList(props) {
    const res = orderList(props);
    return res;
  }
}

const itemInfo = (props) => {
  const { urlInfo, itemInfo } = props;
  const keyToParse = ['GrpList', 'TableList', 'SetMenuList', 'menuSettings', 'ChoiceMenuList'];
  
  /** Data Parse */
  keyToParse.forEach((key) => {
    let modify = '';
    switch(key) {
      case 'GrpList':
        modify = 'groupList'
        break;
      case 'TableList':
        modify = 'tableList'
        break;
      case 'SetMenuList':
        modify = 'setMenuList'
        break;
      default:
        modify = key;
        break;
    }
    itemInfo[key] = parseIf(itemInfo[modify]);
  });

  /**Check service time */
  grpCheckServiceTime(itemInfo.GrpList);

  /**Make DisplayGrpList */
  itemInfo.DisplayGrpList = itemInfo.GrpList.filter(grp =>
    !grp.hidden && (grp.isService === true || grp.isService === undefined)
  );
  
  /**Sorting DisplayGrpList */
  itemInfo.DisplayGrpList = sorting(itemInfo.DisplayGrpList);

  /**pos 메뉴명 사용여부 결정 */
  usingPosName(itemInfo);

  /**Pos에 언어 설정이 있는 경우 */
  itemInfo.GrpList.map((grp) => {
    supportedLangs.forEach(lang => usePosLang(grp, lang));
  });

  /**TableInfo 및 TableList 설정 */
  const result = itemInfo.TableList.filter(table => 
    table.tableNo.toString() === Number(urlInfo.tableNo).toString()
  );
  itemInfo.TableInfo = result[0];
  itemInfo.TableInfo.TableName = itemInfo.TableInfo.tableName;
  itemInfo.TableList = sortTableList(itemInfo.TableList);   // Table Mode시 테이블 순서
  
  return itemInfo;
}

const itemList = (props) => {
  const { itemList, itemInfo } = props;
  const keyToParse = [
    'nameLang', 'serviceTime', 'descriptionLang', 'posDescriptionLang', 'posNameLang'
  ];

  // Data Parse
  itemList.map(item => {
    keyToParse.forEach(key => { item[key] = parseIf(item[key])})
    
    // Pos Name 사용 설정.
    setName(itemInfo.menuSettings?.usePosMenuFirst, item);
    
    if(item.serviceTime)
      item.isService = checkServiceTime(item.serviceTime);

    // Sort Info 설정.
    setSortInfo(itemInfo.GrpList, item, itemInfo.optionName.toUpperCase());
  });

  return itemList;
}

const menuList = (props) => {
  const { itemInfo, itemList } = props;
  let choice = [];

  itemInfo.GrpList.forEach(grp => {
    grp.menus = itemList?.filter(item => item.groupCode?.includes(grp.GrpCode || grp.gcode));
    grp.menus = sorting(grp.menus);
    grp.menus = bnh(grp.menus);
  });

  itemInfo.SetMenuList.forEach((choiceItem) => {
    itemList.forEach(item => {
      if(choiceItem.subcode === item.itemCode) {
        const _item = { ...item, MenuCode: choiceItem.setcode, price: choiceItem.unitprice }
        item.isOptionItem = true;
        choice.push(_item);
      }else
        item.isOptionItem = false;
    });
  });
  itemInfo.ChoiceItemList = choice;
}

const makeOption = (props) => {
  const { item, rdxInfo } = props;
  const choiceGrps = [];
  const curChoiceItemList = [];

  if(item.isset !== '1') return { choiceGrps: [], choiceItems: [] };
  
  const setMenus = 
    rdxInfo.itemInfo.SetMenuList.filter(setmenu => setmenu.setcode === item.itemCode);

    /*
     * setMenus에서 settype별로 옵션 메뉴 객체로 묶기
     * 각각에 배열에 접근하는 방법은 settype의 값을 인덱스로 접근한다.
     * settype의 값이 1, 2, 3, .... 이므로 sepSetType배열의 인덱스는 0이 아닌 1부터 시작.
    */
  
  const sepSetType = setMenus.reduce((acc, curr) => {
    const settype = curr.settype;
    if(!acc[settype])
      acc[settype] = [];
    
    acc[settype].push(curr);

    return acc;
  }, {});

  /**
   * choice Group가 없어서 인위적으로 만들어 줘야 함.
   */
  Object.keys(sepSetType).forEach(key => {
    const _item = {
      menuCode: sepSetType[key][0].setcode,
      groupCode: 'choice' + key,
      groupName: '추가',
      itemName: '추가',
      itemName2: '추가',
      settype: parseInt(key),
      sortOrder: parseInt(key),
      selected: 0,
    }
    choiceGrps.push(_item);
  });
  let curChoiceItems = rdxInfo.itemInfo.ChoiceItemList.filter(choice => choice.MenuCode === item.itemCode)
  curChoiceItems = curChoiceItems.map(mapChoiceInfo);
  
  curChoiceItems.forEach((item) => {
    setMenus.forEach((set) => {
      if(set.subcode === item.itemCode){
        item = {
          ...item,
          Price: item.price,
          settype: set.settype
        }
        curChoiceItemList.push(item)
      }
    });
  });
  
  return { choiceGrps, choiceItems: curChoiceItemList }
}

const makeEssExt = (props) => {
  const { ess, ext, choiceItems, posName } = props;

  makeOptionItems(ext, choiceItems, posName);
}

const makeCartItems = (props) => {
  const { item, checkedList } = props;
  const optionInfo = makeOptionInfo(checkedList);
  const fullName = item.itemName2 + '#onl' + optionInfo.fullName;
  const options = Array.from(checkedList);

  const _item = {
    ...item,
    defaultItemName: item.itemName,
    itemName: fullName,
    desc: item.description,
    best: item.isBest,
    hot: item.isHot,
    new: item.isNew,
    price: parseInt(item.price) + (optionInfo.optionPrice),
    count: props.itemCount,
    optionPrice: optionInfo.optionPrice,
    payAmount: parseInt(item.price) + (optionInfo.optionPrice),
    isReceived: false,
    dp: false,
    options,
  }
  checkedList.clear();
  
  return _item;
}

const orderList = async (props) => {
  const { rdxInfo } = props;
  const { setLoading } = props;
  
  try {
    setLoading(true);
    const res = await oasisposProxy({
      apiName: 'orderInq',
      body: {
        "userIP": "",
        "branch": rdxInfo.shopInfo.storeCode,
        "tableGroup": rdxInfo.itemInfo.TableInfo.tableGroup,
        "tableNo": rdxInfo.itemInfo.TableInfo.tableNo,
        "requestTime": getReqTime_oasispos(),
        "content": ""
      }
    });
    
    let sum = 0;
    let mainMenu = res.result.orderList;
    mainMenu.forEach(item => {
      item.ItemName = item.mName,
      item.Qty = item.qty,
      item.TotalAmt = item.amount,
      sum = sum + item.TotalAmt
    })
    
    return {
      orders: mainMenu,
      status: res.result.errorCode === '0000',
      amount: sum || 0,
    }
  }catch(e) {
    console.log('Get orderlist error: ', e);
  }finally { setLoading(false) }
}

const mapChoiceInfo = (item => ({
  ...item,
  choiceInfo: {
    ItemCode: item.itemCode,
    MenuCode: item.MenuCode,
    Price: parseInt(item.price),
    Qty: 0,
  }
}));

export default function excuteOasis(props) {
  return posMap[props.type](props);
}
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { userGet } from "../../lib/ec2-api-lib";
import { ErrorMsg } from "../../settings/texts";
import { useLocation, useNavigate } from "react-router-dom";

import Loader from "../../components/Loader";

const CheckSession = () => {
  const [isLoading, setLoading] = useState(false);
  const nav = useNavigate();
  const loc = useLocation();

  useEffect(() => {
    const onLoad = async () => {
      try {
        setLoading(true);
        await Auth.signOut();
        const resCur = await Auth.currentCredentials();
        const cognitorUser = await Auth.currentUserPoolUser();
        const res = await userGet({ username: cognitorUser.username });

        if(res.err)
          throw new Error(ErrorMsg.userGetError);
        const shopId = res.result.Item.id;
        if(loc.pathname === '/tablet')
          nav(`/v1-1/${shopId}/master`);
      }catch(e) {
        console.log('Session check error: ', e);
        if(loc.pathname === '/tablet')
          nav('/tablet/login');
      }finally { setLoading(false) }
    }

    onLoad();
  }, []);
  return(
    <div>
      { isLoading ? <Loader /> : null }
    </div>
  )
}

export default CheckSession;
const signoutcnt = 5;

const mobile = 'v1';
const tablet = 'v1-1';
const orderTime = 1000 * 60 * 19;
const lastMinute = 1000 * 60;

const tableNick = ['테이블', '태이블', '호', '룸', '테라스'];
const ess = 'ESSENTIAL';
const qty = 'ESSENTIAL|QTYMGR';
const maxSize = 5000000;
const icon = 48;
const menuSize = 100;
const menuSizeVertical = 80;
const bnh = 30;
const sIcon = 30;
const tori = '선택옵션 (택 1~3개 선택가능)';
const bottomHeight = 90;
const defalutLang = ['KR', 'EN'];

const primaryColor = '#05b8a0';
const primaryDarkColor = '#07746A';
const secondaryColor = '#4ec6e5';

const langsMap = {
  "KO": "한 국 어",
  "KR": "한 국 어",
  "EN": "English",
  "ZH": "中国大陆",
  "CN": "中国大陆",
  "JA": "日 本 語",
  "JP": "日 本 語",
  "ES": "Español",
  "DE": "Deutsch",
  "FR": "Français",
}
const langIconMap = {
  "KO": "한",
  "KR": "한",
  "EN": "A",
  "JA": "あ",
  "JP": "あ",
  "ZH": "中",
  "CN": "中",
  "ES": "ES",
  "FR": "FR",
  "DE": "DE"
}

export default {
  signoutcnt,
  mobile,
  tablet,
  orderTime,
  lastMinute,
  tableNick,
  ess,
  qty,
  maxSize,
  icon,
  menuSize,
  bnh,
  sIcon,
  tori,
  langsMap,
  langIconMap,
  bottomHeight,
  defalutLang,
  menuSizeVertical,
}

import { useEffect } from 'react';

import Routes_Onl from './navigation/routes';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const preventGoBack = () => {
    history.go(1);
  }
  useEffect(() => {
    window.addEventListener('beforeunload', preventGoBack);

    return () => {
      window.removeEventListener('beforeunload', preventGoBack);
    }
  }, []);
  return (
    <div className="App">
      <Routes_Onl />
    </div>
  );
}

export default App;

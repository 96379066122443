import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { resetCart } from "../../../redux/cart";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Left } from '../../../assets/icons/left.svg';

import doOrder from "../do-order";
import CartItem from "../BodySection/CartItem";
import constants from "../../../settings/constants";
import BottomButton from "../../../components/BottomButton";

import './MobileCart.css';

const MobileCart = (props) => {
  const { rdxInfo, openCartPage, cartItems } = props;
  const { setOpenCartPage, setOrderMessage, setOrderResult } = props;

  const [totlaPrice, setTotalPrice] = useState(0);

  
  const dispatch = useDispatch();
  const urlInfo = useSelector((state) => state.shop.urlInfo);
  
  const posName = rdxInfo.shopInfo.posName;
  const type = 'Order';

  // 모달이 열렸을 때 스크롤을 방지하는 코드
  // useEffect(() => {
  //   let scrollPosition;

  //   if (openCartPage) {
  //     scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  //     document.body.style.position = 'fixed';
  //     document.body.style.top = `-${scrollPosition}px`;
  //     document.body.style.width = '100%';
  //     document.documentElement.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.position = '';
  //     document.body.style.top = '';
  //     document.documentElement.style.overflow = '';
      
  //     // 스크롤 위치를 복원
  //     window.scrollTo({
  //       top: scrollPosition,
  //       behavior: 'auto',
  //     });
  //   }

  //   return () => {
  //     document.body.style.position = '';
  //     document.body.style.top = '';
  //     document.documentElement.style.overflow = '';
      
  //     // 모달이 닫히면 스크롤 위치를 복원
  //     if (scrollPosition !== undefined) {
  //       window.scrollTo({
  //         top: scrollPosition,
  //         behavior: 'auto',
  //       });
  //     }
  //   };
  // }, [openCartPage]);


  useEffect(() => {
    let sum = 0;
    cartItems.forEach(item => sum += item.payAmount);

    setTotalPrice(sum);
  }, [cartItems]);

  const handleClosed = () => {
    setOpenCartPage(false);
  }

  const onOrder = async () => {
    const orderProps = { 
      ...props,
      posName, type, urlInfo,
    }
    const res = await doOrder(orderProps);
    
    // 주문 결과 메세지 Dialog 설정.
    setOrderMessage({title: res.title, body: res.body});
    setOrderResult(true);

    if(res.status) {
      dispatch(resetCart());
      setOpenCartPage(false);
    }
  }
  return(
    <Modal
      show={openCartPage}
      animation
      fullscreen
      onHide={handleClosed}
      dialogClassName="fullscreen-modal"
    >
      <Modal.Header className="app-bar">
        <div className="undo-button" onClick={handleClosed}>
          <Left width={constants.sIcon} color="white" onClick={handleClosed} />
        </div>
        <div className="tool-typography">{rdxInfo.systemMsg.ui.cartTitle}</div>
      </Modal.Header>
      <Modal.Body className="cart-body">
        <CartItem { ...props } />
      </Modal.Body>
      <Modal.Footer className="modal-footer-custom">
        <BottomButton title='goOrder' uiText={rdxInfo.systemMsg} price={totlaPrice} click={onOrder} />
      </Modal.Footer>
    </Modal>
  )
}


export default MobileCart;


{/* <Dialog
fullScreen
open={openCartPage}
onClose={handleClosed}
TransitionComponent={Slide}
>
<AppBar className="app-bar">
  <Toolbar className="tool-bar">
    <IconButton className="icon" edge='start' onClick={handleClosed} aria-label="back">
      <BackIcon />
    </IconButton>
    <Typography className="tool-typography" variant="h6" component='div'>
      {rdxInfo.systemMsg.ui.cartTitle}
    </Typography>
  </Toolbar>
</AppBar>
<Box className='dialog-body'>
  <Typography></Typography>
  <Box sx={orderBtnsx}>
    <BottomButton uiText={rdxInfo.systemMsg} click={doOrder}/>
  </Box>
</Box>
</Dialog> */}
import React from 'react';

import App from './App';
import conf from './settings/conf';
import store from './redux/store';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';

Amplify.configure({
  Auth: {
    mandatorySignIn     : false,
    region              : conf.cognito.REGION,
    userPoolId          : conf.cognito.USER_POOL_ID,
    identityPoolId      : conf.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId : conf.cognito.APP_CLIENT_ID
  },
  Storage: {
    region          : conf.s3.REGION,
    bucket          : conf.s3.BUCKET,
    identityPoolId  : conf.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name      : conf.apiGateway.USER.API_NAME,
        endpoint  : conf.apiGateway.USER.URL,
        region    : conf.apiGateway.REGION
      },
      {
        name      : conf.apiGateway.SHOP.API_NAME,
        endpoint  : conf.apiGateway.SHOP.URL,
        region    : conf.apiGateway.REGION
      },
      {
        name      : conf.apiGateway.TAG.API_NAME,
        endpoint  : conf.apiGateway.TAG.URL,
        region    : conf.apiGateway.REGION
      },
      {
        name      : conf.apiGateway.SMS.API_NAME,
        endpoint  : conf.apiGateway.SMS.URL,
        region    : conf.apiGateway.REGION
      },
      {
        name      : conf.apiGateway.POS.API_NAME,
        endpoint  : conf.apiGateway.POS.URL,
        region    : conf.apiGateway.REGION
      },
    ]
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

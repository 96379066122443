import { useEffect, useState }    from "react"
import { ErrorMsg }               from "../../settings/texts";
import { useNavigate }            from "react-router-dom";
import { ntag424VerifyPiccData }  from '../../lib/ec2-api-lib';

import conf         from "../../settings/conf";
import Loader       from "../../components/Loader";
import querystring  from 'query-string';


const Auth = ({urlInfo}) => {
  const [isLoading, setLoading] = useState(false);
  
  const nav = useNavigate();
  
  const onLoad = async () => {
    const parsed = querystring.parse(window.location.search);
    let body = {
      piccdata: parsed.e,
      mac: parsed.m,
      tableNo: urlInfo.tableNo,
      mode: urlInfo.ver === 'v2' ? 'debug' : '',
      ver: urlInfo.ver === 'v2' ? 'v1' : urlInfo.ver,
    }
    try {
      setLoading(true);
      const res = await ntag424VerifyPiccData({body});
      
      setLoading(false);
      if(!res.status) {
        nav(`/${body.ver}/notFound/`, { replace: true })
        return;
      }
      
      nav(`/${body.ver}/${urlInfo.shopId}/${parseInt(urlInfo.tableNo)}?token=${res.result.token}`,
        { replace: true }
      );
      
      return;
    }catch(e) {
      setLoading(false);
      if(conf.debug) console.log('Auth error: ', e);
      alert(ErrorMsg.authErr);
    }
  }

  useEffect(() => {
    onLoad();
  }, []);
  return(
    <>
      { isLoading ? <Loader /> : null }
    </>
  )
}

export default Auth;
import { thousandsSeperator } from "../lib/utils";

import './Price.css';

const Price = ({price, lang, type}) => {
  const value = thousandsSeperator(price);
  let style;
  
  switch(type) {
    case 'detail':
      style = 'detail-main-price';
      break;
    case 'option':
      style = 'option-item-list';
      break;
    case 'cart':
      style = 'cart-item-list';
      break;
    case 'order':
      style = 'order-button';
      break;
    case 'ol-option':
      style = 'ol-option-price';
      break;
    case 'menu':
    default:
      style = 'menu-item-list';
  }
  return(
    <div className={style}>
      { lang.langName === '한'
        ? <div>{value}{lang.ui.won}</div>
        : <div>{lang.ui.won}{value}</div>
      }
    </div>
  )
}

export default Price;
import { Element } from "react-scroll";

import React from "react";
import MenuItem from "./MenuItem";

import './ItemSection.css';

const Items = (props) => {
  const { rdxInfo } = props;
  const grpList = rdxInfo.itemInfo.DisplayGrpList;
  return(
    <div>
      { grpList.map((grp, idx) => {
        
        const length = grp.menus?.length;
        const disLength = grp?.menus?.filter(item => item.removed).length;
        const timeLength = grp?.menus?.filter(item => item.isService === false).length;
        
        if(!length || (length === disLength) || (length === timeLength)) return;
        return(
          <Element key={idx} name={grp.displayName}>
            <div className="tab-content">
              <h4>{grp.displayName}</h4>
              { grp.menus.map((item, idx) => {
                  const isShow = (item.isService || item.isService === undefined) && !item.removed && !item?.isOptionItem;
                return(
                  isShow && 
                    <MenuItem { ...props } key={idx} item={item} isLast={length === idx + 1} />
                )
              })}
            </div>
          </Element>
        )
      })}
    </div>
  )
}

export default Items;
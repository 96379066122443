import { createSlice } from "@reduxjs/toolkit";

const initialState = { cartItems: [] }

export const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addCartItems: (state, action) => {
      const newItems = replaceItemExist(state.cartItems, action.payload.item);
      if(newItems)
        state.cartItems = newItems;
      else
        state.cartItems = [ ...state.cartItems, action.payload.item]; 
    },
    removeCartItems: (state, action) => {
      let arr = [ ...state.cartItems ];
      state.cartItems = 
        arr.filter(item => item.itemName !== action.payload.itemName);
      
      if(state.cartItems.length < 1)
        state.cartItems = initialState.cartItems;
    },    
    updateItemCount: (state, action) => {
      
      const newItems = state.cartItems;
      const index = newItems.findIndex(item => item.itemName === action.payload.itemName);
      
      state.cartItems[index].count      = action.payload.count;
      state.cartItems[index].payAmount  = newItems[index].count * newItems[index].price;
    },
    resetCart: (state) => {
      state.cartItems = initialState.cartItems;
    }
  }
});

const replaceItemExist = (items, item) => {
  for(let i = 0; i < items.length; i++) {
    if(items[i].itemName === item.itemName) {
      items[i].count += item.count;
      return items;
    }
  }
  return null;
}

export const { addCartItems, removeCartItems, resetCart, updateItemCount } = cart.actions;
export default cart.reducer;
import { Button } from "react-bootstrap";
import { unionposProxy2 } from "../../../lib/ec2-api-lib";

import CustomDialog from "../../../components/CustomDialog";

import './IconSection.css';

const TableCall = (props) => {
  const { rdxInfo } = props;
  const { setTableCall, setResultDialog, setResultTitle } = props;
  
  const onCancel = () => setTableCall(false);
  
  const onCall = async (item) => {
    try {
      const body = {
        "StoreCode": rdxInfo.shopInfo.storeCode,
        "TableCode": rdxInfo.itemInfo.TableInfo.TableCode,
        "CallData": {
          "CallType": "Text",
          "CallText": item.toString()
        }
      };

      const res = await unionposProxy2({ apiName: 'tableCall', body: body });
      const resTitle = res.result.CODE === 'S00000' 
        ? rdxInfo.systemMsg.dialog.callSuccess 
        : rdxInfo.systemMsg.dialog.callFailure;
      
      setResultTitle(resTitle);
      setTableCall(false);

    }catch(e) {
      setResultTitle(rdxInfo.systemMsg.dialog.callFailure);
      setTableCall(false);      
      console.log('call staff error: ', e.message);
    }finally { setResultDialog(true) }
  }
  return(
    <CustomDialog
      visible={true}
      type='cancel'
      title={rdxInfo.systemMsg.ui.callStaff}
      sysMsg={rdxInfo.systemMsg}
      onCancel={onCancel}
    >
      { rdxInfo.itemInfo.tableCallList.map((item, idx) =>{
        return(
          <div className="staff-call-button" key={idx}>
            <Button className="custom-outline-button" variant="outline-custom" onClick={()=>onCall(item)}>
              { item }
            </Button>
          </div>
        )
      })}
    </CustomDialog>
  )
}

export default TableCall;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedItem: {},
  itemOption: {},
}

export const item = createSlice({
  name: 'item',
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selectedItem = action.payload;
    },
  }
});

export const { setSelected } = item.actions;
export default item.reducer;
import { ErrorMsg } from "../../settings/texts";
import { shopItemOptionGet } from "../../lib/ec2-api-lib";

import excute from "./pos/excute";

const getItemInfo = async (props) => {
  const { urlInfo, posName } = props;
  
  const shopId = urlInfo.shopId;
  
  try {
    const modiPosName = posName === 'UNIONPOS' ? posName.toLowerCase() : posName;
    const res = await shopItemOptionGet(shopId, modiPosName);
  
    if(!res.status)
      throw new Error(ErrorMsg.getItemInfo);
    return excute({ ...props, type: 'ItemInfo', itemInfo: res.result.Item});
  }catch(e) {
    console.log('Get item info error: ', e.message);
  }
}

export default getItemInfo;
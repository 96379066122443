import { useState }     from "react";
import { Button }       from "react-bootstrap";
import { TabletUI }     from "../../settings/texts";
import { useDispatch }  from "react-redux";
import { setTableInfo } from "../../redux/shop";

import CustomDialog from "../../components/CustomDialog";

import './SetTableNo.css';

const SetTableNo = (props) => {
  const { sysMsg, visible, tableList } = props;
  const { onSelect } = props;
  
  const [selected, setSelected] = useState(null);

  const dispatch = useDispatch();

  const onClicked = (e) => {
    const tb = tableList.find((table) => table.TableName === e.target.value);
    
    dispatch(setTableInfo(tb));  
    onSelect(false);
  }
  return(
    <CustomDialog
      type= 'cancel'
      visible={visible}
      sysMsg={sysMsg}
      title={TabletUI.tableNo}
      onClose={() => onSelect(false)}
    >
      <TableList tableList={tableList} onClicked={onClicked} />
    </CustomDialog>
  )
}

export default SetTableNo;

function TableList({tableList, onClicked}) {
  
  return (
    <div className="table-list">
      {tableList?.map((item, idx) => (
        <Button
          key={idx}
          value={item.TableName}
          className="custom-outline"
          onClick={(e) => onClicked(e)}
        >
          {item.TableName}
        </Button>
      ))}
    </div>
  );
}
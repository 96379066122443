import { ErrorMsg }     from "../../settings/texts";
import { shopItemList } from "../../lib/ec2-api-lib";

import excute from "./pos/excute";

const getItemList = async (props) => {
  const { urlInfo, posName } = props;
  
  const ver     = urlInfo.ver;
  const shopId  = urlInfo.shopId;
  const token   = urlInfo.token;
  
  try {
    const res = await shopItemList(shopId, token, posName, ver);
    
    if(!res.status)
      throw new Error(ErrorMsg.getItemList)
    
    return excute({...props, type: 'ItemList', itemList: res.result.Items});
  }catch(e) {
    console.log('Get item list error: ', e.message);
  }
}

export default getItemList;
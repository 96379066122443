const orderResult = (props) => {
  const { res, rdxInfo, } = props;
  
  const msg = rdxInfo.systemMsg.dialog;
  const codeMapping = {
    'S00000': { status: true, title: msg.orderSuccess, body: msg.orderComplete },
    'E00038': { status: false, title: msg.warnning, body: msg.tableLocked },
    'E00026': { status: false, title: msg.warnning, body: msg.posDisconnect },
    'Table Locked': { status: false, title: msg.warnning, body: msg.tableLocked },
  };

  let resultMessage = {
    status: false,
    title: '',
    body: ''
  }
  if(res.status === false) {
    resultMessage = {
      status: false,
      title: msg.warnning,
      body: msg.jwtError,
    }
  }else if(codeMapping[res.result.CODE]){
    resultMessage = codeMapping[res.result.CODE];
  }else {  // status가 true 이지만 코드가 없는경우 처리
    resultMessage = {
      status: false,
      title: msg.warnning,
      body: msg.orderFail,
    }
  }

  return resultMessage;
}

export default orderResult;
import Counter from '../../../components/Counter';

import './BodySection.css';

const ItemCounter = ({count, title, border, setCount}) => {
  return(
    <div className="ItemCounter">
      { border && <div className="item-counter-border-section" /> }
      <div className="item-counter-section">
        <div>{title}</div>
        <Counter count={count} setCount={setCount} size='large' />
      </div>
    </div>
  )
}

export default ItemCounter;
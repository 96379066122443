import { Button }       from "react-bootstrap";
import { ReactComponent as Shopping } from '../assets/icons/shopping.svg';

import Price from "./Price";

import './BottomButton.css';

const BottomButton = ({title, price, click, uiText, disabled = false}) => {

  return(
    <div className="BottomButton">
      <Button className="go-button" onClick={click} disabled={disabled}>
        <div className="go-button-title">
          { title === 'cart' && <Shopping fill="white" /> }
          { title !== 'cart' && <Price price={price} lang={uiText} /> }
          <div className="go-button-text">{uiText.ui[title]}</div>
        </div>
      </Button>
    </div>
  )
}

export default BottomButton;
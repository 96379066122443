import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeTableName } from "./common";

import logo from '../../assets/images/logo_text.png';
import constants from "../../settings/constants";
import CustomDialog from "../../components/CustomDialog";

import './TitleSection.css';

const DeviceMap = {
  Mobile(props) {
    return Mobile(props);
  },
  Tablet(props) {
    return Tablet(props);
  }
}

const TitleSection = (props) => {
  const { rdxInfo, device } = props;
  const { setTableNoDlg } = props;

  const [count, setCount] = useState(0);
  const [tbName, setTbName] = useState('');
  const [isSignOut, setSignOut] = useState(false);

  const nav = useNavigate();

  useEffect(() => {
    rdxInfo.itemInfo.TableInfo &&
      setTbName(makeTableName(rdxInfo.systemMsg, rdxInfo.itemInfo.TableInfo));
  }, [rdxInfo.itemInfo.TableInfo]); // [rdxInfo]

  useEffect(() => {
    count === constants.signoutcnt && setSignOut(true);
  }, [count]);

  const onCounter = () => {
    setCount(prev => prev + 1);
  }
  
  const onOk = () => {
    setCount(0);
    setSignOut(false);
    nav('/tablet', {replace: true}); // 로그인 페이지로 이동.
  }
  const onCancel = () => {
    setCount(0);
    setSignOut(false);
  }
  const callInputDlg = () => {
    setTableNoDlg(true);
  }

  const tbNameProps = {
    tbName, rdxInfo, isSignOut, onCounter, callInputDlg, setSignOut, onOk, onCancel,
  }
  return DeviceMap[device](tbNameProps);
}


function Mobile(props) {
  const { tbName, rdxInfo, isSignOut } = props;
  const { callInputDlg, onCounter, onOk, onCancel } = props;
  const shopName = rdxInfo.shopInfo.shopName;
  
  return(  
    <div className="title-section">
      <div className="shopName" onClick={onCounter}>{shopName}</div>
      <div className="mobile-tableName" onClick={callInputDlg}>{tbName}</div>
      <CustomDialog
        visible={isSignOut}
        type='both'
        title='로그아웃'
        sysMsg={rdxInfo.systemMsg}
        onOk={onOk}
        onCancel={onCancel}
      >

      </CustomDialog>
    </div>
  )
}

function Tablet(props) {
  const { tbName } = props;
  const { callInputDlg, onCounter } = props;

  return(
    <div className="left-block">
      <img src={logo} width='75px' onClick={onCounter} />
      <div className="tablet-tableName" onClick={callInputDlg}>{tbName}</div>
  </div>
  )
}
export default TitleSection;

import getShopInfo    from './get-shop-info';
import getItemList    from './get-item-list';
import getItemInfo    from './get-item-info';
import queryString    from 'query-string';
import createMenuList from './create-menu-list';

const init = async (props) => {
  const { setLoading } = props;
  
  const token = queryString.parse(window.location.search).token;
  let urlInfo = {
    ...props.urlInfo,
    token,
  };
  
  try {
    setLoading(true);
    const shopInfo = await getShopInfo(urlInfo);
    const posName = shopInfo.posName;
    const itemInfo = await getItemInfo({ ...props, posName });
    const itemList = await getItemList({ ...props, posName, itemInfo, urlInfo });

    createMenuList({ itemInfo, itemList, posName });
    
    return { itemInfo, itemList, shopInfo, urlInfo }
  }catch(e) {
    console.log('initialize error: ', e);
  }finally { setLoading(false) }
}

export default init;
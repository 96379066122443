import { useRef, useEffect, useState } from "react";
import { scroller, Element } from "react-scroll";
import { makeNewline } from "../lib/utils";

import constants from "../settings/constants";

import './VerticalTab.css';

const VerticalTab = ({ grpList, height }) => {
  const [tabs, setTabs] = useState([]);
  const [clicked, setClicked] = useState(0);
  const [menuWidth, setMenuWidth] = useState(0);

  const scrollableTabsRef = useRef(null);
  
  useEffect(() => {
    const grp = grpList.map(item => ({
      name: item.name,
      displayName: item.displayName,
      selected: false
    }));
    setTabs(grp);

    const menuElement = document.getElementById('gr-section');
    if(menuElement) {
      setMenuWidth(menuElement.offsetWidth);
    }

  }, [grpList, clicked]);

  const handleSelect = (selectedKey, idx) => {
    setClicked(idx);
    scroller.scrollTo(selectedKey, {
      duration: 0,
      delay: 0,
      smooth: 'easeOutQuad',
      offset: -menuWidth
    });
  }

  const scrollUp = () => {
    if (scrollableTabsRef.current) {
      scrollableTabsRef.current.scrollBy({
        top: -200,
        behavior: 'smooth'
      });
    }
  };

  const scrollDown = () => {
    if (scrollableTabsRef.current) {
      scrollableTabsRef.current.scrollBy({
        top: 200,
        behavior: 'smooth'
      });
    }
  };

  if (!tabs.length) return null;

  return (
    <div 
      className={'scrollable-tabs-container-t'} 
      style={{height: height ? window.innerHeight - constants.bottomHeight : '100%'}}
    >
      {/* <button className="scroll-arrow up" onClick={scrollUp}>
        <img src={previous} height='24px' />
      </button> */}
      <div className="scrollable-tabs-t" ref={scrollableTabsRef}>
        { tabs.map((grp, idx) => {
          // 3글자 이상 이름중 띄어쓰기로 구분되어 있는 이름에 개행문자 넣기.
          const name = makeNewline(grp.displayName);
          return(
            <button 
              key={idx} 
              className="tab-button-t" 
              onClick={() => handleSelect(grp.displayName, idx)}
            >
              {name}
            </button>
          )
        })}
      </div>
      {/* <button className="scroll-arrow down" onClick={scrollDown}>
        <img src={next} height='24px' />
      </button> */}
    </div>
  );
}

export default VerticalTab;
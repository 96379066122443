import { getIp, sortCartItems } from "../../lib/utils";
import { okposProxy, unionposProxy2 } from "../../lib/ec2-api-lib";
import { getReqTime_oasispos, oasisposProxy } from "../../lib/oasis-api-lib";
import { goToPayKis, notifyOrderToStore, payDataCreate } from '../../lib/ec2-api-lib';

import orderResult from './order-result';
import getOrderInfo from "./get-order-info";
import makeOrderData from "../data/pos/make-order-data";

const posMap = {
  UNIONPOS(props) {
    const res = union(props);
    return res;
  },
  OKPOS(props) {
    const res = ok(props);
    return res;
  },
  OASIS_POS(props) {
    const res = oasis(props);
    return res;
  }
}

const union = async (props) => {
  const { urlInfo, rdxInfo, cartItems, phoneNum, posName } = props;
  const { setLoading } = props;
  
  const payOptions = rdxInfo.shopInfo.payOptions;
  const sorted = await sortCartItems(cartItems);
  const OrderData = await makeOrderData({ items: sorted, rdxInfo, posName });
  const tableInfo = rdxInfo.itemInfo.TableInfo;
  
  const orderProps = {
    OrderData,
    token     : urlInfo.token,
    shopId    : rdxInfo.shopInfo.shopId,
    TableCode : tableInfo.TableCode,
    StoreCode : rdxInfo.shopInfo.storeCode,
    OrderQty  : cartItems.length.toString(),
    ApiPosNo  : (tableInfo.PosNo === undefined || tableInfo.PosNo === '') ? '100' : tableInfo.PosNo,
  }
  
  try {
    const orderInfo = getOrderInfo(cartItems);
    const shopId = rdxInfo.shopInfo.shopId;

    setLoading(true);

    if(payOptions?.isPayable) {
      const body = {
        ...orderProps,
        orderName: orderInfo.orderName,
        orderAmt: orderInfo.orderAmt,
        orderNotifyOptions: rdxInfo.shopInfo.orderNotifyOptions,
      }
      let res = await payDataCreate({
        ver: urlInfo.ver,
        posName: rdxInfo.shopInfo.posName,
        shopId,
        body,
      });
      goToPayKis({ shopId, orderId: res.result.orderId });

      if(res.result.status) {
        res.result.CODE = 'S00000';
      }

      return orderResult({ ...props, res });
    }else {
      const res = await unionposProxy2({ apiName: 'order', body: orderProps, ver: urlInfo.ver});
      
      if(rdxInfo.shopInfo.orderNotifyOptions?.toShop) {
        const body = {
          shopId: rdxInfo.shopInfo.shopId,
          orderId: res.result.saveOrder?.orderId,
          phone_number: rdxInfo.shopInfo.orderNotifyOptions?.toUser ? phoneNum : '01000000000',
          orderAmt: orderInfo.orderAmt,
          orderName: orderInfo.orderName
        }
        notifyOrderToStore({ body });
      }
      // console.log('body: ', orderProps)
      // let res = {status: true, result:{CODE:'S00000'}};
      
      const result = orderResult({ ...props, res }); 
      
      return result;
    }
  }catch(e) {
    console.log('do order error: ', e.message);
  }finally { setLoading(false) }
}

const ok = async (props) => {
  const { urlInfo, rdxInfo, posName, cartItems, phoneNum } = props;
  const { setLoading } = props;

  const orderInfo = await makeOrderData({items: cartItems, posName});
  const orderProps = {
    "TABLE_CD" : rdxInfo.itemInfo.TableInfo.TABLE_CD,
    "FLOOR_NO" : rdxInfo.itemInfo.TableInfo.FLOOR_NO,
    "ORDER_INFO" : orderInfo
  }
  
  try {
    setLoading(true);
    const res = await okposProxy({
      apiName: 'order',
      body: {
        token: urlInfo.token,
        storeCode: rdxInfo.shopInfo.storeCode,
        shopId: rdxInfo.shopInfo.shopId,
        orderData: orderProps,
      },
      ver: urlInfo.ver,
    });
    
    if(rdxInfo.shopInfo.orderNotifyOptions?.toShop) {
      const oi = getOrderInfo(cartItems);
      const body = {
        shopId: rdxInfo.shopInfo.shopId,
        orderId: res.result.saveOrder?.orderId,
        phone_number: rdxInfo.shopInfo.orderNotifyOptions?.toUser ? phoneNum : '01000000000',
        orderAmt: oi.orderAmt,
        orderName: oi.orderName
      }
      notifyOrderToStore({ body });
    }

    const errCode = res.err?.message.includes('9003') || res.status
      ? 'S00000'
      : res.err?.message.includes('500')
      ? '500'
      : res.err?.message;
      
    const message = {
      result: { CODE: errCode }
    }

    return orderResult({ ...props, res: message });
  }catch(e) {
    console.log('order error: ', e.message);
  }finally { setLoading(false) }
}

const oasis = async(props) => {
  const { urlInfo, rdxInfo, posName, cartItems, phoneNum } = props;
  const { setLoading } = props;
  const payOptions = rdxInfo.shopInfo.payOptions;
  const tableInfo = rdxInfo.itemInfo.TableInfo;
  
  const orderList = await makeOrderData({ items: cartItems, rdxInfo, posName });
  const ip = await getIp();

  
  const orderProps = {
    "userIP": "",
    "shopId": rdxInfo.shopInfo.shopId,
    "branch": rdxInfo.shopInfo.storeCode,
    "tableGroup": tableInfo.tableGroup,
    "tableNo": tableInfo.tableNo,
    "requestTime": getReqTime_oasispos(),
    "content": "",
    "personCnt": 1,
    "orderList": orderList
  }

  try {
    setLoading(true);

    const orderInfo = getOrderInfo(cartItems);
    const shopId = rdxInfo.shopInfo.shopId;

    if(payOptions?.isPayable) {
      const body = {
        ...orderProps,
        orderName: orderInfo.orderName,
        orderAmt: orderInfo.orderAmt,
        orderNotifyOptions: rdxInfo.shopInfo.orderNotifyOptions,
      }

      let res = await payDataCreate({
        ver: urlInfo.ver,
        posName: rdxInfo.shopInfo.posName,
        shopId,
        body,
      });

      goToPayKis({ shopId, orderId: res.result.orderId });
      
    }else {
      const res = await oasisposProxy({
        apiName: 'orderInput',
        body: orderProps,
        ver: urlInfo.ver
      });
      
      let errCode;
      if(res.status)
        errCode = res.result?.errorCode === '0000' ? 'S00000' : '9001';
      else
        errCode = '9001';
      const message = { result: { CODE: errCode } }
      return orderResult({ ...props, res: message });
    }
  }catch(e) {
    console.log('Error Order: ', e.message);
  }finally { setLoading(false) }
}
const doOrder = async (props) => {
  const res = posMap[props.posName](props);
  return res;
}

export default doOrder;
import { ErrorMsg } from "../../../settings/texts";
import { okposProxy, onlItemOptionGet } from "../../../lib/ec2-api-lib";
import { setName, setSortInfo, sorting, sortTableList, usingPosName } from "../../../lib/utils";
import { bnh, checkServiceTime, grpCheckServiceTime, makeOptionInfo, parseIf } from "../../../lib/utils";

import makeOptionItems  from "./make-option-items";
import okOptionsMapping from "./ok-mapping";

const posMap = {
  ItemInfo(props) {
    const res = itemInfo(props);
    return res;
  },
  ItemList(props) {
    const res = itemList(props);
    return res;
  },
  MenuList(props) {
    const res = menuList(props);
    return res;
  },
  Options(props) {
    const res = makeOption(props);
    return res;
  },
  EssExt(props) {
    const res = makeEssExt(props);
    return res;
  },
  CartItems(props) {
    const res = makeCartItems(props);
    return res;
  },
  OrderList(props) {
    const res = orderList(props);
    return res;
  }
}

const itemInfo = async (props) => {
  const { urlInfo, itemInfo, posName } = props;
  
  const keyToOptions = ['optionGroupList', 'optionClassList', 'optionItemList'];
  const keyToParse = [
    'MidGrpList', 'SmGrpList', 'SdaCdList', 'SdaClsList', 'SdsCdList',
    'SdsClsList', 'SdsGrList', 'TuClsList', 'TableList', 'menuSettings'
  ];

  /** Get onl option */
  const res = await onlItemOptionGet({ shopId: urlInfo.shopId, posName });

  /** parsing onl option */
  if(res.status) {
    const options = res.result?.Item;
    keyToOptions.forEach((key) => {
      itemInfo[key] = parseIf(options[key]);
    });
  }

  /** parsing data */
  itemInfo.GrpList = parseIf(itemInfo.TuClsList);
  
  keyToParse.forEach((key) => {
    itemInfo[key] = parseIf(itemInfo[key]);
  });

  /** check service time */
  grpCheckServiceTime(itemInfo.GrpList);
  
  /** make DisplayGrpList */
  const temp = itemInfo.TuClsList.map((grp) => grp.GrpCode);
  const tu = itemInfo.GrpList.filter((grp) => temp.includes(grp.GrpCode));
  
  itemInfo.DisplayGrpList = tu.filter(grp => 
    !grp.hidden && (grp.isService === true || grp.isService === undefined)
  );

  /** Sorting DisplayGrp */
  itemInfo.DisplayGrpList = sorting(itemInfo.DisplayGrpList);
  
  /** pos 메뉴명 사용여부 결정 */
  usingPosName(itemInfo);
  
  /** Choice class 생성 */
  if(Array.isArray(itemInfo.SdsClsList) && Array.isArray(itemInfo.SdaClsList))
    itemInfo.ChoiceClsList = itemInfo.SdaClsList.concat(itemInfo.SdsClsList);

  /** Sds와 Sda를 합쳐 Choime Items 생성 */
  if(Array.isArray(itemInfo.SdsCdList) && Array.isArray(itemInfo.SdaCdList))
    itemInfo.ChoiceItemList = itemInfo.SdaCdList.concat(itemInfo.SdsCdList);

  /** SdsCd에 그룹코드 매칭. */
  itemInfo.SdsCdList.forEach((cd) => {
    const result = itemInfo.SdsClsList.find((cls) => cls.SDS_CLS_CD === cd.SDS_CLS_CD);
    if(result)
      cd.SDS_GRP_CD = result.SDS_GRP_CD;
  });

  /** TableInfo */
  let result = itemInfo.TableList.filter(item => 
    Number(item.TABLE_CD) === Number(urlInfo.tableNo));
  result = result[0] ? result[0] : urlInfo.tableNo ? urlInfo.tableNo : 'master';
  
  itemInfo.TableInfo = result;
  itemInfo.TableList = sortTableList(itemInfo.TableList);

  return itemInfo;
}

const itemList = (props) => {
  const { itemList, itemInfo } = props;
  const keyToParse = ['nameLang', 'serviceTime', 'descriptionLang'];
  
  itemList.map((item) => {
    item.groupCode = item.tuClassList;

    keyToParse.forEach(key => {
      item[key] = parseIf(item[key]);
    })
    
    // item posname 설정
    setName(itemInfo.menuSettings?.usePosMenuFirst, item);
    
    if(item.serviceTime)
      item.isService = checkServiceTime(item.serviceTime);
    
      /** SortInfo */
      setSortInfo(itemInfo.GrpList, item, itemInfo.optionName.toUpperCase());
  });

  return itemList;
}

const menuList = (props) => {
  const { itemInfo, itemList } = props;
  
  // reduce를 사용하여 groupCode를 키로 하는 groupedItems 객체 생성.
  const groupedItems = itemList.reduce((acc, item) => {
    if(item.groupCode) {
      item.groupCode.forEach(code => {
        if(!acc[code]) {
          acc[code] = [];
        }
        acc[code].push(item);
      });
    }
    return acc;
  }, {});
  
  // 각 grp에 대해 groupedItems 객체에서 grp.GrpCode에 해당하는 menus를 찾음.
  itemInfo.GrpList.forEach(grp => {
    const menus = groupedItems[grp.GrpCode] || [];
    
    grp.menus = grp.itemList?.map(itemCode => 
      menus.find(menu => menu.itemCode === itemCode)
    );
    
    grp.menus = bnh(grp.menus);
  });

  const same = itemInfo.ChoiceItemList.filter(choice => 
    itemList.some(item => (item.SDA_CD === choice.SDA_CD) && item.SDA_CD !== undefined)
  );
}

// const makeOption = (props) => {
//   const { item, rdxInfo } = props;
//   const choiceGrps = [];
//   const choiceItems = [];
//   let mapped;

//   if(item.SDA_CLS_CD === '' && item.SDS_CLS_CD === '')
//     return;
//   if(!item.optionGroupCode) {
//     const sda = rdxInfo.itemInfo.ChoiceItemList.filter(chItem => item.SDA_CLS_CD === chItem.SDA_CLS_CD);
//     const sds = rdxInfo.itemInfo.ChoiceItemList.filter(chItem => item.SDS_GRP_CD === chItem.SDS_GRP_CD);
//     const chItems = [ ...sda, ...sds ];
    
//     chItems.forEach(item => {
//       item = {
//         ...item,
//         choiceInfo: {
//           ItemCode: item.PROD_CD,
//           MenuCode: item.SDS_CLS_CD || item.SDA_CLS_CD,
//           Price: parseInt(item.SDS_PROD_UPRC) || 0,
//           Qty: 0,
//         }
//       }
//       choiceItems.push(item);
//     });
//     rdxInfo.itemInfo.ChoiceClsList.forEach(grp => {
//       if((item.SDA_CLS_CD === grp.SDA_CLS_CD) || (item.SDS_GRP_CD === grp.SDS_GRP_CD))
//         choiceGrps.push(grp);
//     });

//     mapped = okOptionsMapping({choiceGrps, choiceItems, posName: 'OKPOS'});
//   }else {
//     let optGrp = rdxInfo.itemInfo.optionGroupList.filter(grp => grp.groupCode === item.optionGroupCode);
//     optGrp = optGrp[0];
//     const optCls = [];

//     optGrp.classList.forEach(clsCode => {
//       rdxInfo.itemInfo.optionClassList.forEach(cls => {
//         if(clsCode === cls.classCode)
//           optCls.push(cls);
//       });
//     });
    
//     let optItems = [];
//     rdxInfo.itemInfo.optionItemList.forEach(optItem => {
//       optCls.map(cls => {
//         cls.itemList.map(item => {
//           if(optItem.itemCode === item)
//             optItems.push(optItem);
//         });
//       });
//     });

//     let op = [];
//     let chItems = [];
//     optItems.forEach(opt => {
//       let opttemp = rdxInfo.itemList.filter(item => item.itemCode === opt.itemCode);
//       opttemp = { ...opttemp[0], optionPrice: opt.optionPrice }
//       op.push(opttemp);
//     });

//     op.forEach(item => {
//       item = {
//         ...item,
//         choiceInfo: {
//           ItemCode: item.itemCode,
//           MenuCode: item.SDS_GRP_CD,
//           Price: item.optionPrice,
//           Qty: 0
//         }
//       }
//       chItems.push(item);
//     });
//     mapped = okOptionsMapping({ choiceGrps: optCls, choiceItems: chItems, posName: 'ONLPOS' });
//   }
  
//   return { choiceGrps: mapped.choiceGrps, choiceItems: mapped.choiceItems }
// }

const makeEssExt = (props) => {
  const { ess, ext, choiceItems, posName } = props;
  ess.length && makeOptionItems(ess, choiceItems, posName);
  ext.length && makeOptionItems(ext, choiceItems, posName);
}

const makeOption = (props) => {
  const { item, rdxInfo } = props;
  const choiceGrps = [];
  
  if(item.SDA_CLS_CD === '' && item.SDS_CLS_CD === '') return;

  const mapChoiceItem = (chItem) => ({
    ...chItem,
    choiceInfo: {
      ItemCode: chItem.PROD_CD || chItem.itemCode,
      MenuCode: chItem.SDS_CLS_CD || chItem.SDA_CLS_CD || chItem.SDS_GRP_CD,
      Price: parseInt(chItem.SDS_PROD_UPRC) || chItem.optionPrice || 0,
      Qty: 0,
    }
  });

  if(!item.optionGroupCode) {
    const sdaItems = rdxInfo.itemInfo.ChoiceItemList.filter(chItem => item.SDA_CLS_CD === chItem.SDA_CLS_CD);
    const sdsItems = rdxInfo.itemInfo.ChoiceItemList.filter(chItem => item.SDS_GRP_CD === chItem.SDS_GRP_CD);
    const chItems = [...sdaItems, ...sdsItems].map(mapChoiceItem);
    
    rdxInfo.itemInfo.ChoiceClsList.forEach(grp => {
      if(item.SDA_CLS_CD === grp.SDA_CLS_CD || item.SDS_GRP_CD === grp.SDS_GRP_CD) {
        choiceGrps.push(grp);
      }
    });

    return okOptionsMapping({ rdxInfo, choiceGrps, choiceItems: chItems, posName: 'OKPOS'});
  } else {
    const optCls = [];
    const optGrp = rdxInfo.itemInfo.optionGroupList.find(grp => grp.groupCode === item.optionGroupCode);
    
    if (!optGrp) return { choiceGrps: [], choiceItems: [] };

    optGrp.classList.forEach(clsCode => {
      const cls = rdxInfo.itemInfo.optionClassList.find(cls => cls.classCode === clsCode);
      if (cls) {
        optCls.push(cls);
      }
    });
    const optItems = rdxInfo.itemInfo.optionItemList.filter(optItem => 
      optCls.some(cls => cls.itemList.includes(optItem.itemCode))
    );
    
    const chItems = optItems.map(optItem => {
      const opttemp = rdxInfo.itemList.find(rdxItem => rdxItem.itemCode === optItem.itemCode);
      return {
        ...opttemp,
        optionPrice: optItem.optionPrice,
        choiceInfo: {
          ItemCode: opttemp.itemCode,
          MenuCode: opttemp.SDS_GRP_CD,
          Price: optItem.optionPrice,
          Qty: 0,
        }
      }
    });
    
    return okOptionsMapping({ choiceGrps: optCls, choiceItems: chItems, posName: 'ONLPOS' });
  }
}

function makeCartItems(props) {
  const { item, checkedList, orgDtlNo, rdxInfo } = props;
  const { setOrgDtlNo } = props;

  const optionInfo = makeOptionInfo(checkedList);
  const fullName = item.itemName2 + '#onl' + optionInfo.fullName;
  const options = Array.from(checkedList);
  
  // 옵셔의 수량을 설정.
  const opts = options.map((item) => {
    if(item.SDS_CLS_CD || item.SDA_CLS_CD) {
      const temp = { ...item, Qty: 1, SDS_ORG_DTL_NO: orgDtlNo.toString() }

      return temp;
    }else {
      let temp;
      rdxInfo.itemList.forEach(raw => {
        if(raw.itemCode === item.itemCode) {
          temp = { ...raw, Qty: 1, SDS_ORG_DTL_NO: orgDtlNo.toString() }
        }
      });
      return temp;
    }
  });
  
  const _item = {
    itemName: fullName,
    itemCode: item.itemCode,
    desc: item.description,
    best: item.isBest,
    hot: item.isHot,
    new: item.isNew,
    grpSortOrder: item.grpSortOrder,
    itemGroup: item.itemGroup,
    menuType: item.menuType,
    menuType2: item.menuType2,
    soldOut: item.soldOut,
    image: item.image,
    price: parseInt(item.price) + parseInt(optionInfo.optionPrice),
    count: parseInt(props.itemCount),
    optionPrice: parseInt(optionInfo.optionPrice),
    payAmount: parseInt(item.price) + parseInt(optionInfo.optionPrice),
    options: opts,
    defaultItemName: item.itemName,
    isReceived: false,
    dp: false,
    orgDtlNo: orgDtlNo.toString(),
    sortInfo: item.sortInfo,
  }

  checkedList.clear();
  setOrgDtlNo((Math.floor(Math.random() * 9000) + 1000).toString());

  return _item;
}

const orderList = async (props) => {
  const { rdxInfo } = props;
  const { setLoading } = props;

  try {
    setLoading(true);
    
    let orderInfo = [];
    let totalAmt = 0;
    const res = await okposProxy({
      apiName: 'orderInfo',
      body: {
        storeCode: rdxInfo.shopInfo.storeCode,
        tableCode: rdxInfo.itemInfo.TableInfo.TableCode,
      }
    });
    
    const is8000 = res.err?.message.split('-')[0].trim() === '8000';
    
    if(!res.status && !is8000)
      throw new Error(ErrorMsg.orderListError);
    else if(!res.status && is8000)
      orderInfo = [];
    else {
      orderInfo = res.result.DATA[0].ORDER_INFO;
      orderInfo = orderInfo?.map(item => {
        let sel = rdxInfo.itemList.find(it => it.itemCode === item.PROD_CD);
        sel = { 
          ...item, 
          ItemName: sel?.itemName2 || sel?.itemName, 
          Qty: item.PROD_CNT, TotalAmt: parseInt(item.DCM_AMT)
        }
        let sel2 = rdxInfo.itemInfo.ChoiceItemList.find(it => it?.SDA_CD === item?.SDA_CD);
        const name = sel.ItemName ? sel.ItemName : sel2?.SDA_NM;
        sel = { ...sel, ItemName: name }

        return sel;
      });
      orderInfo.map(item => {
        const temp = orderInfo.filter(it => it.SDS_PTCD === item.PROD_CD);
        
        item.options = temp;
      })
      orderInfo.forEach(item => { totalAmt += parseInt(item.DCM_AMT)});
      
    }
    return {
      orders: orderInfo,
      status: true,
      amount: totalAmt
    }
  }catch(e) {
    console.log('call order list error: ', e.message);
  }finally { setLoading(false) }
}

export default function excuteOk(props) {
  return posMap[props.type](props);
}
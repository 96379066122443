import { useRef }         from "react";
import { optimizingUrl }  from "../lib/utils";

import Loader from "./Loader";

import './Image.css';

const Image = (props) => {
const { src, width, type } = props;
const { setHeight }        = props;

const ref = useRef(null);
const url = src ? optimizingUrl(src) : '';

const handleLoad = () => {
  if(ref.current && setHeight)
    setHeight(ref.current.clientHeight)
}
return(
  <div className="Image">
    { url
      ? <div>
          <img
            className={type === 'menu' ? 'image-menu' : ''}
            ref={ref}
            src={url}
            width={width}
            onLoad={handleLoad}
          />
          <div className={type === 'detail' ? "overlay":''} />
        </div>
      : <Loader />
    }
  </div>
)
}

export default Image;
import Price from "../../../components/Price";

import './BodySection.css';

const MainInfo = (props) => {
  const { item, systemMsg } = props;

  const best = item.isBest ? systemMsg.ui.best : '';
  const newItem = item.isNew ? systemMsg.ui.new : '';
  const hot = item.isHot ? systemMsg.ui.hot : '';
  const bnh = newItem + ' ' + best + ' ' + hot;

  return(
    <div className="Item">
      <div className="detail-item-container">
        <div>
          { item.isBest || item.isNew || item.isHot 
            ? <div className="detail-item-bnh">{bnh}</div>
            : <div style={{marginTop: item.image !=='' ? '20px' : '5px'}} />
          }
        </div>
        <div className="detail-item-name">{item.itemName2}</div>
        <div className="detail-item-desc">
          { item.description ? item.description : item.itemName2 }
        </div>
        <div className="detail-item-price">
          <div>{systemMsg.ui.price}</div>
          <Price price={item.price} lang={systemMsg} type='detail' />
        </div>
      </div>
    </div>
  )
}

export default MainInfo;
import { shopGet }  from '../../lib/ec2-api-lib';
import { parseIf }  from "../../lib/utils";
import { ErrorMsg } from "../../settings/texts";

import conf from "../../settings/conf";

const getShopInfo = async (urlInfo) => {
  // const { urlInfo } = props;
  
  try {
    const res = await shopGet({ shopId: urlInfo.shopId });
    if(!res.status) throw new Error(ErrorMsg.getShopInfo);
    res.result.Item.payOptions          = parseIf(res.result.Item.payOptions);
    res.result.Item.noticeOptions       = parseIf(res.result.Item.noticeOptions);
    res.result.Item.orderNotifyOptions  = parseIf(res.result.Item.orderNotifyOptions);
    res.result.Item.userAppUiOptions   = parseIf(res.result.Item.userAppUiOptions);
    
    return res.result.Item;
  }catch(e) {
    if(conf.debug) 
      console.error('Get ShopInfo error: ', e.message);
  }
}

export default getShopInfo;
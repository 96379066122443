import { useState } from "react";

import CustomDialog from "../../components/CustomDialog";

import './GetPhoneNumber.css';

const GetPhoneNumber = (props) => {
  const { rdxInfo, isPhone } = props;
  const { setIsPhone, setPhoneNum, setOpenCartPage } = props;

  const [inputText, setInputText] = useState('');
  const [validPhone, setValidPhone] = useState(false);

  const onOk = () => {
    const phoneRegex = /^010\d{8}$/;
    
    if(!phoneRegex.test(inputText)) {
      setValidPhone(true);
      setInputText('');
      
      return;
    }
    setPhoneNum(inputText);
    setIsPhone(false);
    setOpenCartPage(true);
  }

  const onCancel = () => {
    setIsPhone(false);
  }

  const handleChange = (e) => {
    setInputText(e.target.value);
  }
  
  return (
    <CustomDialog
      type='both'
      visible={isPhone}
      title={rdxInfo.systemMsg.ui.phoneTitle}
      sysMsg={rdxInfo.systemMsg}
      onOk={onOk}
      onClose={onCancel}
    >
      { validPhone && <div className="wrong-number">{rdxInfo.systemMsg.ui?.wrongPhone}</div> }
      <input className="phone-num" type="tel" value={inputText} onChange={handleChange}/>
      <div className="warn-phone">{rdxInfo.systemMsg.ui.warnPhone}</div>
    </CustomDialog>
  )
}

export default GetPhoneNumber;
import { useEffect, useState }     from "react";
import { setItemInfo, setUrlInfo, setSystemMsg } from "../../redux/shop";

import { isTablet }    from "react-device-detect";
import { useDispatch } from "react-redux";
import { isEmptyObject } from "../../lib/utils";
import { setSystemLanguage } from '../../settings/langs';

import conf         from "../../settings/conf";
import init         from "../data/init";
import Loader       from "../../components/Loader";
import MenuList     from "../Menu/MenuList";
import constants    from '../../settings/constants';
import CustomDialog from '../../components/CustomDialog';

const Home = (props) => {
  const { urlInfo } = props;
  
  const [sysMsg, setSysMsg] = useState();
  const [initData, setInitData] = useState();
  const [isLoading, setLoading] = useState(true);
  const [showMenus, setShowMenus] = useState(true);
  const [timeWarning, setTimeWarning] = useState(false);
  
  const dispatch = useDispatch();
  const initProps = { ...props, setTimeWarning }

  useEffect(() => {
    const lang = navigator.language.toUpperCase();
    setSysMsg(setSystemLanguage(lang));
    
    const timerWarning = setTimeout(() => setTimeWarning(true), constants.orderTime);
    const timerOut = setTimeout(() => {
      setShowMenus(false);
      setTimeWarning(false);
    }, constants.orderTime + (1000 * 60));
    
    const path = window.location.pathname.split('/');
    const url = {
      ver: path[1],
      shopId: path[2],
      tableNo: path[3],
    }

    const onStart = async () => {
      const infos = await init({ 
        urlInfo: urlInfo.shopId ? urlInfo : url,
        setLoading 
      });

      if(!isEmptyObject(infos)) {      // infos가 빈 오브젝트가 아닐때
        dispatch(setItemInfo(infos));
        dispatch(setUrlInfo(infos.urlInfo));
        dispatch(setSystemMsg(setSystemLanguage(lang)));
        
        setInitData(infos);
      }
      if(conf.debug) console.log('infos: ', infos);
    };
    onStart();
    return () => {
      clearTimeout(timerWarning);
      clearTimeout(timerOut);
    }
  }, [urlInfo]);
  if(isLoading) {
    return <Loader />
  }
  
  return(
    <div>
      { initData && <MenuList mobile={!isTablet} timeover={!showMenus} { ...initProps } /> }
      { timeWarning && 
        <CustomDialog 
          visible
          sysMsg={sysMsg}
          title={sysMsg.dialog.oneMin1}
          onOk={()=>setTimeWarning(false)}
        >
          { sysMsg.dialog.oneMin2 }
        </CustomDialog>
      }
    </div>
  )
}

export default Home;
import { useEffect, useRef, useState } from "react"

import Items from "./ItemSection/Items";
import CustomTab from '../../components/CustomTab';
import VerticalTab from "../../components/VerticalTab";

const MenuSection = (props) => {
  const { rdxInfo, cartItems } = props;

  const [isTop, setTop] = useState(false);
  const [itemsWidth, setItemsWidth] = useState('100%');

  const grSectionRef = useRef(null);
  const menuSectionRef = useRef(null);
  const grpList = rdxInfo.itemInfo.DisplayGrpList;
  const isVertical = rdxInfo.shopInfo.userAppUiOptions?.groupBarPosition === 'left';
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { capture: true });
    return () => { window.removeEventListener('scroll', handleScroll); }
  }, []);

  const handleScroll = () => {
    const pos = document.getElementById('menus');
    if (pos) {
      const top = pos?.getBoundingClientRect()?.top;
      top <= 0 ? setTop(true) : setTop(false);
    }
  }

  // Items 넓이 isTop 일때 설정
  useEffect(() => {
    const updateItemsWidth = () => {
      if (grSectionRef.current && menuSectionRef.current) {
        const grSectionWidth = grSectionRef.current.offsetWidth;
        const menuSectionWidth = menuSectionRef.current.offsetWidth;
        const calculatedWidth = isTop ? menuSectionWidth - grSectionWidth - 5 : '100%';
        setItemsWidth(`${calculatedWidth}px`);
      }
    };

    updateItemsWidth(); // 초기 계산
    window.addEventListener('resize', updateItemsWidth); // 윈도우 리사이즈 시 다시 계산

    return () => {
      window.removeEventListener('resize', updateItemsWidth);
    };
  }, [isTop]); // isTop이 변경될 때마다 업데이트
  
  return(
    <div className={isVertical ? "MenuSection-v" : 'MenuSection'} ref={menuSectionRef}>
      { isVertical
        ? <div id='gr-section' className={ isTop ? 'is-top-v' : ''} ref={grSectionRef}>
            <VerticalTab grpList={grpList} height={cartItems.length} />
          </div>
        : <div id='gr-section' className={ isTop ? 'is-top' : ''}>
            <CustomTab grpList={grpList} />
          </div>
      }
      <div style={{width: '5px'}} />
      <div className="items-wrap">
        <div style={{width: itemsWidth}}>
          <Items { ...props } />
        </div>
      </div>
    </div>  
  )
}

export default MenuSection;
import { useEffect, useState } from "react";
import { setSystemLanguage } from "../../settings/langs";

import Loader from "../../components/Loader";
import CustomDialog from "../../components/CustomDialog";

import './Login.css';
import { ErrorMsg } from "../../settings/texts";
import { Auth } from "aws-amplify";
import { shopGet, userGet } from "../../lib/ec2-api-lib";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const Login = (props) => {
  const [sysLang, setSysLang] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  
  const dispatch = useDispatch();
  const nav = useNavigate();
  const loc = useLocation();

  useEffect(() => {
    const lang = (navigator.language).split('-')[0].toUpperCase();
    
    setSysLang(setSystemLanguage(lang));
  }, []);

  const onLogin = async () => {
    try {
      setLoading(true);
      
      const user = await Auth.signIn(username, password);
      const res = await userGet({ username });
      const shopId = res.result.Item.id;
      const url = {
        shopId,
        tableNo: 'master',
        ver: 'tablet'
      }
      // const resShop = await shopGet({ shopId });
      
      if(loc.pathname === '/tablet/login' || loc.pathname === 'tablet/login/') {
        nav(`/v1-1/${shopId}/master`, { state: url });
      }
    }catch(e) {
      console.log('login error: ', e);
      setLoginError(true);
    }finally { setLoading(false) }
  }

  const handleUsername = (e) => {
    setUserName(e.target.value);
  }
  const handlePassword = (e) => {
    setPassword(e.target.value);
  }

  if(isLoading)
    <Loader />
  return(
    <CustomDialog
      visible
      sysMsg={sysLang}
      title="'오늘' 로그인"
      onOk={onLogin}
    >
      <input className="username" value={username} onChange={handleUsername} />
      <input className="password" value={password} onChange={handlePassword} type="password"/>
      { loginError ? <div className="err-msg">{ErrorMsg.loginError}</div> : null }
    </CustomDialog>
  )
}

export default Login;
import { useEffect, useState } from "react";

import './BodySection.css';
import { Form } from "react-bootstrap";
import Price from "../../../components/Price";

const Essential = (props) => {
  const { optionItems, rdxInfo, checkOption, checkedList } = props;
  const { setCheckOption, setCheckedList, setSelectedCount } = props;
  
  const [initOption, setInitOption] = useState(checkedList);
  const [value, setValue] = useState(optionItems[0].itemName2);
  
  useEffect(() => {
    // default 옵션을 checkedList 에 추가.
    initOption.add(optionItems[0]);
    optionItems[0].Qty = 1;

    setCheckedList(initOption);
    setCheckOption(!checkOption);
  }, []);

  const onChange = (item, e) => {
    const updateSet = new Set(checkedList);
    const prevItem = Array.from(checkedList).find(
      opt => optionItems.some(oi => oi.itemCode === opt.itemCode
    ));
    setValue(e.target.textContent || e.target.value);

    if(prevItem) {
      // 기존항목 삭제
      
      updateSet.delete(prevItem);
      setSelectedCount(prev => prev + 1);
    }
    updateSet.add(item);
    item.Qty = 1;
    setSelectedCount(prev => prev + 1);
    setCheckedList(updateSet);
  }

  return(
    <div className="Essential">
      <Form>
        { optionItems.map((item, idx) => {
          const price = item.choiceInfo.Price !== undefined ? item.choiceInfo.Price : item.price;

          return(
            <div key={idx}>
              { !item.isSoldout &&
                <div className="essential-section">
                  <Form.Check
                    className={value === item.itemName2 ? 'custom-radio':''}
                    type="radio"
                    name="essential"
                    id={`radio-${item.itemName2}`}
                    label={item.itemName2}
                    value={item.itemName2}
                    checked={value === item.itemName2}
                    onChange={(e) => onChange(item, e)}
                  />
                  <Price price={price} lang={rdxInfo.systemMsg} type='option' />
                </div>
              }
            </div>
          )
        })}
      </Form>
    </div>
  )
}

export default Essential;
import { Route, Routes } from "react-router-dom";

import Auth         from '../Pages/Init/Auth';
import Home         from "../Pages/Init/Home";
import Login        from "../Pages/Login/Login";
import NotFound     from "../Pages/Init/NotFound";
import queryString  from "query-string";
import CheckSession from "../Pages/Login/CheckSession";

export default function Routes_Onl() {
  const basePath = '/:ver/:shopId/:tableNo/';
  const auth = basePath + 'auth';
  const tabletPath = '/tablet';
  const loginPath = '/tablet/login';
  const pathname = window.location.pathname.split('/');
  const parsed = queryString.parse(window.location.search);
  
  let urlInfo = {
    ver: pathname[1],
    shopId: pathname[2],
    tableNo: pathname[3],
    token: parsed.token,
  }
  
  return(
    <Routes>
      <Route path={auth} element= {<Auth urlInfo={urlInfo} />} />
      <Route path={tabletPath} element={ <CheckSession />} />
      <Route path={loginPath} element={ <Login /> } />
      <Route path={basePath} element={<Home urlInfo={urlInfo} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
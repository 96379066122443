import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  urlInfo: {},
  itemInfo: {
    TableInfo: undefined
  },
  systemMsg: {},
  itemList: {},
  shopLang: ''
};

export const shop = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setSystemMsg: (state, action) => {
      state.systemMsg = action.payload;
    },
    setUrlInfo: (state, action) => {
      state.urlInfo = action.payload;
    },
    setItemInfo: (state, action) => {
      const { shopInfo, itemInfo, itemList } = action.payload;

      state.shopInfo = shopInfo;
      state.itemInfo = itemInfo;
      state.itemList = itemList;
    },
    setLangInfo: (state, action) => {
      state.shopLang = action.payload.shopLang;
    },
    setTableInfo: (state, action) => {
      state.itemInfo.TableInfo = action.payload;
    }
  }
});

export const { setUrlInfo, setSystemMsg, setItemInfo, setLangInfo, setTableInfo } = shop.actions;
export default shop.reducer;
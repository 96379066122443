import { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addCartItems } from '../../../redux/cart';
import { ReactComponent as Left} from '../../../assets/icons/left.svg';

import Image from '../../../components/Image';
import excute from '../../data/pos/excute';
import MainInfo from '../BodySection/MainInfo';
import constants from '../../../settings/constants';
import OptionInfo from '../BodySection/OptionInfo';
import ItemCounter from '../BodySection/ItemCounter';
import BottomButton from '../../../components/BottomButton';

import './MobileDetail.css';

const MobileDetail = (props) => {
  const { rdxInfo, selectedItem, openDetailPage, checkedList } = props;
  const { setOpenDetailPage, setCheckedList } = props;

  const [isSame, setSame] = useState(true);
  const [hasOption, setHasOption] = useState(false);
  const [menuPrice, setMenuPrice] = useState();
  const [imgHeight, setImgHeight] = useState();
  const [checkOption, setCheckOption] = useState(false);
  const [selectedOptionCount, setSelectedOptionCount] = useState(0);
  
  const [orgDtlNo, setOrgDtlNo] = useState(
    (Math.floor(Math.random() * 9000) + 1000).toString()
  );
  const [itemCount, setItemCount] = useState(
    selectedItem?.ORD_MIN_QTY ? parseInt(selectedItem?.ORD_MIN_QTY) : 1
  );
  
  const isBorder = rdxInfo.shopInfo.posName === 'OKPOS'
    ? (selectedItem.SDA_CLS_CD !== '' || selectedItem.SDS_GRP_CD !== '') || selectedItem.optionGroupCode
    : hasOption;
  
  const detailProps = {
    ...props,
    checkOption, selectedOptionCount, orgDtlNo, isSame,
    setCheckOption, setSelectedOptionCount, setOrgDtlNo, setSame,
    setHasOption,
  }
  
  const dispatch = useDispatch();
  const bodyClass = selectedItem.image !== '' ? 'modal-body-custom' : 'modal-body-custom header-no-image';
  
  useEffect(() => {
    const arChecked = Array.from(checkedList);
    let sum = 0;

    arChecked?.forEach((item) =>
      sum += (item.choiceInfo.Price * (item.Qty || 1)));
    
      setMenuPrice((+selectedItem.price * itemCount) + (sum * itemCount));
  }, [checkedList, itemCount, checkOption, selectedOptionCount]);

  // 모달이 열렸을 때 스크롤을 방지하는 코드
  // useEffect(() => {
  //   let scrollPosition;

  //   if (openDetailPage) {
  //     scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  //     document.body.style.position = 'fixed';
  //     document.body.style.top = `-${scrollPosition}px`;
  //     document.body.style.width = '100%';
  //     document.documentElement.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.position = '';
  //     document.body.style.top = '';
  //     document.documentElement.style.overflow = '';
      
  //     // 스크롤 위치를 복원
  //     window.scrollTo({
  //       top: scrollPosition,
  //       behavior: 'auto',
  //     });
  //   }

  //   return () => {
  //     document.body.style.position = '';
  //     document.body.style.top = '';
  //     document.documentElement.style.overflow = '';
      
  //     // 모달이 닫히면 스크롤 위치를 복원
  //     if (scrollPosition !== undefined) {
  //       window.scrollTo({
  //         top: scrollPosition,
  //         behavior: 'auto',
  //       });
  //     }
  //   };
  // }, [openDetailPage]);


  const onGoCart = () => {
    const result = excute({ 
      ...props,
      type: 'CartItems',
      posName: rdxInfo.shopInfo.posName,
      item: selectedItem,
      itemCount, orgDtlNo,
      setOrgDtlNo 
    });
    
    checkedList.clear();
    dispatch(addCartItems({ item: result }));
    setOpenDetailPage(false);
  }

  const handleClose = () => {
    setCheckedList(new Set());
    setItemCount(selectedItem?.ORD_MIN_QTY ? parseInt(selectedItem.ORD_MIN_QTY) : 1);
    setOpenDetailPage(false);
  }
  
  return(
    <Modal
      show={openDetailPage}
      onHide={handleClose}
      fullscreen
      dialogClassName='fullscreen-modal'
      animation={true}
    >
      <Modal.Header className='modal-header-custom'>
        { selectedItem.image !== ''
          ? <div className='back-image'>
              <Image type='detail' src={selectedItem.image} width='100%' setHeight={setImgHeight} />
            </div>
          : <div className='detail-overlay' />
        }
        <div className='undo-button' onClick={handleClose}>
          <Left 
            width={selectedItem.image !== '' ? constants.icon : constants.sIcon} 
            color={selectedItem.image !== '' ? 'white' : '#07746A'} 
            onClick={handleClose}
          />
        </div>
      </Modal.Header>
      <Modal.Body className={bodyClass} style={{marginTop: imgHeight}}>
        <MainInfo item={selectedItem} systemMsg={rdxInfo.systemMsg} />
        <OptionInfo item={selectedItem} { ...detailProps } />
        <ItemCounter count={itemCount} title={rdxInfo.systemMsg.ui.qty} border={isBorder} setCount={setItemCount} />
      </Modal.Body>
      <Modal.Footer className='modal-footer-custom'>
        <BottomButton
          title='goCart'
          uiText={rdxInfo.systemMsg}
          price={menuPrice}
          disabled={isSame}
          click={onGoCart}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default MobileDetail;
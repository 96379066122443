import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { setItemInfo, setSystemMsg } from '../../../redux/shop';
import { changeSelectedLangs, setLanguageLocale, setSystemLanguage } from "../../../settings/langs";

import constants from "../../../settings/constants";
import CustomDialog from "../../../components/CustomDialog";

const Language = (props) => {
  const { rdxInfo } = props;
  const { setLanguage } = props;
  
  const dispatch = useDispatch();
  const supported = rdxInfo.itemInfo.menuSettings?.supportedLangs?.length
    ? rdxInfo.itemInfo.menuSettings?.supportedLangs 
    : constants.defalutLang;
  
  const onChange = (lang) => {
    const selected = setLanguageLocale(lang);
    const result = changeSelectedLangs(rdxInfo, selected);
    
    dispatch(setItemInfo({ itemInfo: result, shopInfo: rdxInfo.shopInfo, itemList: result.ItemList }));
    dispatch(setSystemMsg(setSystemLanguage(lang)));
    
    setLanguage(false);
  }

  return(
    <CustomDialog
      type='cancel'
      visible={true}
      title={rdxInfo.systemMsg.ui.changeLang}
      sysMsg={rdxInfo.systemMsg}
      onCancel={()=>setLanguage(false)}
    >
      { supported?.map((lang, idx) => {
        return(
          <div className="staff-call-button" key={idx}>
            <Button className='custom-outline-button' variant='outline-custom' onClick={()=>onChange(lang)}>
              {constants.langsMap[lang]}
            </Button>
          </div>
        )
      })}
    </CustomDialog>
  )
}

export default Language;
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import Price from "../../../components/Price";

import './IconSection.css';

const OrderList = (props) => {
  const { rdxInfo, orderInfo } = props;

  const [modalTitle, setModalTitle] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [orderListModal, setOrderListModal] = useState(false);

  useEffect(() => {
    setModalTitle(rdxInfo.systemMsg.ui.orderListTitle1);
    setTotalAmount(orderInfo.amount);
    setOrderListModal(orderInfo.status);
  }, [orderInfo]);

  const onClose = () => {
    setOrderListModal(false);
  }

  return(
    <Modal
      show={orderListModal}
      onHide={onClose}
      animation
      fullscreen
      dialogClassName="fullscreen-modal"
    >
      <Modal.Header closeButton>
        {modalTitle}
        <div className="ol-header">
          <div>{'\u00A0'+'('+'\u00A0'}</div>
          <Price price={orderInfo.amount} lang={rdxInfo.systemMsg} type='menu' />
          <div>{'\u00A0'+')'}</div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="OrderItems">
          <OrderItems systemMsg={rdxInfo.systemMsg} orderInfo={orderInfo} />
        </div>
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  )
}

export default OrderList;

function OrderItems(props) {
  const { systemMsg, orderInfo } = props;
  const orderList = orderInfo.orders;
  
  return(
    <div>
      { orderList.map((item, idx) => {
        let optionSum = 0;
        
        if(item.SDS_PTCD === '' || item.SDS_PTCD === undefined) {
          return(
            <div className={idx ? "ol-wrapper not-zero" : 'ol-wrapper'} key={idx}>
            <div className="ol-time">{item?.FirstOrderDate?.split('.')[0]}</div>
            <div className="ol-item-name">
              <div>{item.ItemName + ' \u00D7 ' + item.Qty}</div>
              <Price price={item.TotalAmt} lang={systemMsg} />
            </div>
            { item.options?.map((opt, index) => {
              optionSum = optionSum + opt.TotalAmt;
              return(
                <div className="ol-option-name" key={index}>
                  <div>{' \u00B7 ' + opt.ItemName + ' \u00D7 ' + opt.Qty}</div>
                  <Price price={opt.TotalAmt} lang={systemMsg} type='ol-option'/>
                </div>
              )
            })}
            <div className="ol-total-amount">
              <div>{systemMsg.ui.totalPrice}</div>
              <Price price={item.TotalAmt + optionSum} lang={systemMsg} type='menu' />
            </div>
          </div>
          )
        }
        return null;
      })}
    </div>
  )
}
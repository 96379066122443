const getOrderInfo = (cartItems) => {
  try {
    let orderAmt = 0;
    let orderNames = [];

    cartItems.map(cartItem => {
      let itemName = cartItem.itemName.split('#')[0];
      let optPrice = 0;
      const optNameList = [];

      cartItem.options.map((opt) => {
        optNameList.push(opt.itemName2);
        optPrice += opt.Price;
      });
      const optNames = '(' + optNameList.join(',') + ')';

      if(optNameList.length > 0)
        itemName += optNames;
      if(cartItem.count > 1)
        itemName += ' \u00D7 ' + cartItem.count;

      orderNames.push(itemName);
      orderAmt += (parseInt(cartItem.price)) * cartItem.count;

    });

    const orderName = orderNames.join(' , ');

    return { orderAmt: orderAmt.toString(), orderName }
  }catch(e) {
    return { err: { message: e.message }}
  }
}

export default getOrderInfo;
import { useState } from "react";
import { useSelector } from "react-redux";

import NotFound   from "../Init/NotFound";
import MobileMenu from "./Mobile/MobileMenu";
import SetTableNo from '../Init/SetTableNo';

const MenuList = (props) => {
  const { timeover, setTimeWarning } = props;

  const [tableNoDlg, setTableNoDlg] = useState(true);
  const [openCartPage, setOpenCartPage] = useState(false);
  const [openDetailPage, setOpenDetailPage] = useState(false);
  
  const [selectedItem, setSelectedItem] = useState();
  const [checkedList, setCheckedList] = useState(new Set());

  const rdxInfo = useSelector(state => state.shop);
  const cartItems = useSelector(state => state.cart.cartItems);

  const menuProps = { 
    ...props,
    openCartPage, openDetailPage, rdxInfo, cartItems, selectedItem, checkedList,
    setOpenCartPage, setOpenDetailPage, setTableNoDlg, setSelectedItem, setCheckedList
  };
  
  return(
    <div className="MenuList">
      <div>
        { !timeover //|| rdxInfo.urlInfo.ver === 'v2'
          ? <MobileMenu { ...menuProps } />
          : <NotFound setClose={setTimeWarning} />
        }
      </div>
      { rdxInfo.urlInfo.tableNo === 'master' &&
        <SetTableNo 
          visible={tableNoDlg}
          sysMsg={rdxInfo.systemMsg}
          tableList={rdxInfo.itemInfo.TableList}
          onSelect={setTableNoDlg}
        />
      }
    </div>
  )
}

export default MenuList;
import shopReducer from './shop';
import cartReducer from './cart';
import itemReducer from './item';

import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
  reducer: {
    shop: shopReducer,
    cart: cartReducer,
    item: itemReducer,
  },
});

export default store;
import { useEffect }                from 'react';
import { ReactComponent as Sad }    from '../../assets/icons/Sad.svg';
import { ErrorMsg, brandName, tel } from '../../settings/texts';

import CallLink from '../../components/CallLink';

import './NotFound.css';

const NotFound = ({setClose}) => {
  useEffect(() => {
    if(setClose)  setClose(false);
  }, []);
  return(
    <div className="NotFound">
      <div className='info-wrapper'>
        <Sad width={80} />
        <div>{ErrorMsg.notFound}</div>
      </div>
      <div className='as-wrapper'>
        <div>{brandName}</div>
        <CallLink phoneNumber={tel} color={'white'} />
      </div>
    </div>
  )
}

export default NotFound;
import { useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";

import './Counter.css';

const Counter = ({ count, group, checked, setCount, size='small' }) => {
  const [isMax, setMax] = useState(false);
  const [isMin, setMin] = useState(true);
  const containerStyle = (size === 'large') ? 'Counter large' : "Counter small";

  useEffect(() => {
    if(group?.selected >= group?.SetCnt)
      setMax(true);
    else
      setMax(false);
    if(count < 2)
      setMin(true);
    else
      setMin(false);
  }, [checked, group?.selected]);

  const onDecrease = () => {
    if(count < 2) {
      setMin(true);
      return;
    }
    if(isMax)
      setMax(false);

    setCount(count - 1);
  }
  const onIncrease = () => {
    if(group?.selected >= group?.SetCnt) {
      return ;
    }
    if(group?.selected >= group?.SetCnt - 1)
      setMax(true);
    if(isMin)
      setMin(false);

    setCount(count + 1);
  }
  
  return(
    <ButtonGroup className={containerStyle} style={{height: size === 'large' && '35px'}}>
      <Button className={isMin ? "counter-button min" : "counter-button"} onClick={onDecrease}>{'-'}</Button>
      <div>{count}</div>
      <Button className={isMax ? 'counter-button max' : 'counter-button'} onClick={onIncrease}>
        {'+'}
      </Button>
    </ButtonGroup>
  )
}

export default Counter;
const posMap = {
  UNIONPOS(props) {
    const res = union(props);
    return res;
  },
  OKPOS(props) {
    const res = ok(props);
    return res;
  },
  OASIS_POS(props) {
    const res = oasis(props);
    return res;
  }
}

async function union(props) {
  const { items, rdxInfo } = props;
  const OrderData = [];

  items.map(async (item) => {
    let setMenus = [];
    let setItems = [];

    setItems = rdxInfo.itemInfo.SetMenuList?.filter(set => set.MenuCode === item.menuCode);
    const choiceItems = rdxInfo.itemList.filter(item => item.menuType2 === 'CHOICE');
    choiceItems.map(chItem => {
      setItems.map(set => {
        if(chItem.itemCode === set.ItemCode) {
          setItems = setItems.filter(setItem => setItem.ItemCode !== set.ItemCode);
        }
      });
    });
    if(item.menuType2 === 'DYNAMIC' && item.menuCode !== '') {
      setItems = item.options;
    }
    setMenus = setItems?.map(item => {
      return({
        "SetCode" : (item.itemCode || item.ItemCode),
        "SetQty"  : (item.Qty).toString(),
        "Price"   : (item.choiceInfo?.Price * (item.Qty || 1)).toString(),
      });
    });
    
    OrderData.push({
      "ItemCode"  : item.itemCode,
      "Qty"       : item.count.toString(),
      "Price"     : item.price.toString(),
      "IsPack"    : "C",
      "SetMenu"   : setMenus,      
    });
  });

  return OrderData;
}

async function ok(props) {
  const { items } = props;

  let order = [];
  let orderInfo = [];
  const sortedItems = [];

  items.map(item => {
    let temp = item.options.map(opt => {
      if(opt.SDS_CLS_CD || opt.SDA_CLS_CD) {
        const chQty = {
          ...opt,
          Qty: item.count,
          prodType: opt.SDA_CD ? '2' : '1',
          ptCd: opt.SDA_CD ? opt.PROD_CD : item.itemCode,
        }
        sortedItems.push(chQty);

        return chQty;
      }else {
        const chQty = {
          ...opt,
          count: item.count
        };
        sortedItems.push(chQty);

        return chQty;
      }
    });
    /*
     * OKPOS는 사이드 메뉴가 메인메뉴처럼 단품으로 주문해야 하기 때문에 사이드도
     * 메인메뉴 배열에 같이 누적 시킨다.
    */
      temp = [item, ...temp];
      order = [...order, ...temp];
  });

  order.map(item => {
    const price = (item.price - (item?.optionPrice || 0))?.toString();
    orderInfo.push({
      "ORDER_TYPE" : "Y",
      "PROD_CD" : !item.SDA_CD ? item.itemCode : "",
      "PROD_AMT" : price || "0",
      "DCM_AMT" : price || "0",
      "DC_AMT" : "0",
      "POINT_AMT" : "0",
      "TAX_AMT" : price || "0",
      "NOTAX_AMT" : "0",
      "PROD_CNT" : item.count?.toString() || item.Qty?.toString() || "0",
      "PROD_TYPE" : item.prodType || "0",
      "SDS_PTCD" : item.ptCd || "",
      "SDS_GRPCD" : item.SDS_GRP_CD || "",
      "SDS_CLSCD" : item.SDS_CLS_CD || "",
      "SDS_CD" : item.SDS_CD || "",
      "SDA_CLSCD" : item.SDA_CLS_CD || "",
      "SDA_CD" :  item.SDA_CD || "",
      "SDS_ORG_DTL_NO" : item.orgDtlNo || item.SDS_ORG_DTL_NO,
    });
  });

  return orderInfo;
}

const oasis = (props) => {
  const { items } = props;
  const orderList = [];
  
  items.forEach(item => {
    const net = parseInt(((parseInt(item.price) * 10) / 11).toFixed(0));
    const vat = parseInt((parseInt(item.price) / 11).toFixed(0));
    const options = [];
    if(item.options.length) {
      item.options.forEach(opt => {
        const _opt = {
          "mcode": opt.itemCode,
          "mName": opt.itemName2,
          "unitPrice": opt.price,
          "qty": item.count,
          "amount": opt.price * item.count,
          "dcAmount": 0,
          "netAmount": parseInt(((parseInt(opt.price * item.count) * 10) / 11).toFixed(0)),
          "vatAmount": parseInt((parseInt(opt.price * item.count) / 11).toFixed(0)),
        }
        options.push(_opt);
      })
    }

    orderList.push({
      orderNo: '',
      mGroup: item.groupCode,
      mGroupName: item.groupName,
      mCode: item.itemCode,
      mName: item.itemName2,
      unitPrice: parseInt(item.price),
      qty: parseInt(item.count),
      amount: parseInt(item.payAmount - (item.optionPrice * item.count)),
      dcAmount: 0,
      netAmount: net,
      vatAmount: vat,
      isTakeOut: 0,
      state: '',
      optionList: options,
      optionInfo: {}
    })
  });

  return orderList;
}

const makeOrderData = async (props) => {
  return posMap[props.posName](props);
}

export default makeOrderData;
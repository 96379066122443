import Hot  from '../assets/images/hot.png';
import New  from '../assets/images/new.png';
// import Best from '../assets/images/best.png';
import Best from '../assets/images/Best3.gif';
import constants from '../settings/constants';

import './BnH.css';

const BnH = ({item}) => {
  return(
    <div className='BnH'>
      { item.isNew  && !item.isSoldout ? <img src={New}  width={constants.bnh}/> : null }
      { item.isBest && !item.isSoldout ? <img src={Best} width={constants.bnh+20}/> : null }
      { item.isHot  && !item.isSoldout ? <img src={Hot}  width={constants.bnh}/> : null }
    </div>
  )
}

export default BnH;
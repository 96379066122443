import { useEffect, useState, useRef } from 'react';
import { scroller } from 'react-scroll';

import next from '../assets/images/next.png';
import previous from '../assets/images/previous.png';

import './CustomTab.css';

const CustomTab = ({ grpList }) => {
  const [tabs, setTabs] = useState([]);
  const [clicked, setClicked] = useState(0);
  const [menuHeight, setMenuHeight] = useState(0);

  const scrollableTabsRef = useRef(null);
  
  useEffect(() => {
    const grp = grpList.map(item => ({
      name: item.name,
      displayName: item.displayName,
      selected: false
    }));
    setTabs(grp);

    // 탭 버튼 클릭시 스크롤 이동 후 그룹에 가려지는 부분 만큼
    // offset 값을 주기 위해 그룹 높이를 구하기 위한 코드.
    // 3번 클릭해야 정상 값 읽어와짐 ㅡ.ㅠ
    const menuElement = document.getElementById('gr-section');
    if(menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, [grpList, clicked]);

  const handleSelect = (selectedKey, idx) => {
    // onSelectTab(selectedKey);
    
    setClicked(idx);
    scroller.scrollTo(selectedKey, {
      duration: 0,
      delay: 0,
      smooth: 'easeOutQuad',
      offset: -80, //-menuHeight // or -108
    });
  }
  const scrollLeft = () => {
    if (scrollableTabsRef.current) {
      scrollableTabsRef.current.scrollBy({
        left: -200,
        behavior: 'smooth'
      });
    }
  };
  const scrollRight = () => {
    if (scrollableTabsRef.current) {
      scrollableTabsRef.current.scrollBy({
        left: 200,
        behavior: 'smooth'
      });
    }
  };
  if (!tabs.length) return null;

  return (
    <div className="scrollable-tabs-container">
      {/* <button className="scroll-arrow left" onClick={scrollLeft}>
        <img src={previous} height='24px' />
      </button> */}
      <div className="scrollable-tabs" ref={scrollableTabsRef}>
        {tabs.map((grp, idx) => (
          <button 
            key={idx} 
            className="tab-button" 
            onClick={() => handleSelect(grp.displayName, idx)}
          >
            {grp.displayName}
          </button>
        ))}
      </div>
      {/* <button className="scroll-arrow right" onClick={scrollRight}>
        <img src={next} height='24px' />
      </button> */}
    </div>
  );
}

export default CustomTab;
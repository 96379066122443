import { useEffect } from "react";
import { Button, Modal }  from "react-bootstrap";

import './CustomDialog.css';

const CustomDialog = (props) => {
  const { sysMsg, type, title, children, visible } = props;
  const { onClose } = props;
  
  useEffect(() => {
    const fullscreenModal = document.querySelector('.fullscreen-modal .modal-content');

    if (visible) {
      // 반투명한 레이어 추가
      const overlay = document.createElement('div');
      overlay.className = 'custom-overlay';
      fullscreenModal?.parentNode?.insertBefore(overlay, fullscreenModal.nextSibling);
    } else {
      // 반투명한 레이어 제거
      const overlay = document.querySelector('.custom-overlay');
      if (overlay) {
        overlay.remove();
      }
    }

    return () => {
      // Clean up the overlay if the component unmounts
      const overlay = document.querySelector('.custom-overlay');
      if (overlay) {
        overlay.remove();
      }
    };
  }, [visible]);

  return(
    <Modal
      className="custom-modal"
      backdropClassName="custom-modal-backdrop"
      show={visible}
      onHide={onClose}
      style={{zIndex: 1060}}
    >
      <Modal.Header className="header">  {/**closeButton */}
        <Modal.Title className="title">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="body">
        { children }
      </Modal.Body>
      <Modal.Footer className="footer">
        { type === 'both' 
          ? <Both { ...props } sysMsg={sysMsg} />
          : <>
              { type === 'cancel' 
                ? <Cancel { ...props } sysMsg={sysMsg} /> 
                : <Ok { ...props } sysMsg={sysMsg} />
              }
            </>
        }
      </Modal.Footer>
    </Modal>
  )
}

export default CustomDialog;

const Both = (props) => {
  const { sysMsg, onClose, onOk } = props;
  
  return(
    <>
      <Button className="confirm-button" onClick={onOk}>{sysMsg.ui?.ok}</Button>
      <Button className="cancel-button" onClick={onClose}>{sysMsg.ui?.cancel}</Button>
    </>
  )
}

const Cancel = (props) => {
  const { sysMsg, onCancel } = props;
  
  return(
    <Button className="cancel-button" onClick={onCancel}>{sysMsg.ui?.cancel}</Button>
  )
}

const Ok = (props) => {
  const { sysMsg, onOk } = props;
  return(
    <Button className="confirm-button" onClick={onOk}>{sysMsg.ui?.ok}</Button>
  )
}
import axios from "axios";
import conf from "../settings/conf";
import { strDecompress } from "./utils";

// Auth.js          : import { ntag424VerifyPiccData }    from "../lib/ec2-api-lib";    사용
// Home.js          : import { shopGet, shopItemList }    from "../lib/ec2-api-lib";    사용
// Home.js          : resItemList = await shopItemList({shopId});                       사용
// OnlyOrderCart.js : import { unionposProxy }            from "../../lib/ec2-api-lib"; 사용

const apiServerUrl = "https://api.onl.taptag.co.kr";
const payServerUrl = "https://pay.onl.taptag.co.kr";

const axiosCall = async ({ method, path, body }) => {
  try {
    const baseUrl = path === '/api/order-notify' ? payServerUrl : apiServerUrl;
    // const baseUrl = apiServerUrl; //"https://api.onl.taptag.co.kr";    //"https://oneul-api.taptag.co.kr";
    // const okBaseUrl = "http://192.168.0.35:5000";      // 상무님 노트북 서버
    // const okBaseUrl = "http://192.168.0.17:5000";         // test 서버
    
    let res = await axios({
      method,
      url: baseUrl + path,
      // url: okBaseUrl + path,
      data: body,
      headers: { 'x-taptag-key': conf.apiKey }
    });
    
    if (res.status !== 200) throw new Error("Response Error");
    return res.data;
  } catch (e) {
    return { err: { message: e.message } };
  }
}

/** ***************************************************************************
 * @user
 *  **************************************************************************/

export const userGet = async ({ username }) => {  
  const path = `/user?username=${username}`;
  return await axiosCall({ method: "GET", path });
};

/** ***************************************************************************
 * @SHOP
 *  **************************************************************************/
export const shopGet = async ({ shopId }) => {
  const path = `/shop/${shopId}`;
  return await axiosCall({ method: "GET", path });
};

function setPath(apiName, posName) {
  posName = posName.toLowerCase();
  
  posName = (posName === 'unionpos') || (posName === 'oasis_pos') ? '' : posName + '/';
  let path = '';
  if(apiName === 'itemList'){
    path = `/shopitem/${posName}`;
  }else if(apiName === 'itemOption'){
    path = `/shopitem-option/${posName}`;     // OKPOS 전용 path
  }

  return path;
}

export const shopItemList = async (shopId, token, posName, ver) => {
  let path ='';
  path = setPath('itemList', posName) + `${shopId}/list/auth?token=${token}`;
  
  if(conf.debug || ver === 'v1-1') path += '&mode=debug';
  const result = await axiosCall({ method: "GET", path });

  return result;
};

// export const shopItemOptionGet = async (shopId, optionName) => {
//   let path = '';
//   path = setPath('itemOption', optionName) + `${shopId}?optionName=${optionName}`;
  
//   const result = await axiosCall({ method: "GET", path });
  
//   return result;
// };

export const shopItemOptionGet = async (shopId, optionName) => {
  try {
    let path = '';
    path = setPath('itemOption', optionName) + `${shopId}?optionName=${optionName}`;
    let res = await axiosCall({ method: "GET", path });
    
    if(res.err) throw Error(res.err.message);

    const newOptions = { ...res.result.Item }
    if(optionName === 'OASIS_POS') {
      if(newOptions.groupList_comp) {
        newOptions.groupList = strDecompress(newOptions.groupList_comp);
        delete newOptions.groupList_comp;
      }
      if(newOptions.setMenuList_comp) {
        newOptions.setMenuList = strDecompress(newOptions.setMenuList_comp);
        delete newOptions.setMenuList_comp;
      }
      
      return { status: res.status, result: { Item: newOptions } }
    }else {
      return res;
    }
  }catch(e) {
    return { err: { message: e.message }}
  }
}

export const shopItemOptionList = async ({ shopId }) => {
  let path = `/shopitem-option/${shopId}/list`;
  return await axiosCall({ method: "GET", path });
};

export const onlItemOptionGet = ({ shopId, posName }) => {
  if (!posName) return { err: { message: "NO POSNAME" } }
  let path = `/shopitem-option/onl/${shopId}?posName=${posName}`;
  return axiosCall({ method: "GET", path });
};



/** ***************************************************************************
 * @NTAG424
 *  **************************************************************************/
export const ntag424VerifyPiccData = async ({ body }) => {
  // body = {ver, piccdata, tableNo, mac}
  if(conf.debug) body.mode = 'debug';
  const path = "/nfctag/ntag424/verify-piccdata";
  return await axiosCall({ method: "POST", path, body });
};

export const ntag424VerifyToken = async ({ body }) => {
  // body= { token, tableNo}
  const path = "/nfctag/ntag424/verify-token";
  return await axiosCall({ method: "POST", path, body });
};

/** ***************************************************************************
 * @Pos_Api
 *  **************************************************************************/
export const unionposProxy = async ({ apiName, body }) => {
  // 
  // apiName: 아래 중 하나임.
  //  shopInfo | groupList | itemList | soldoutList | imageList | tableList | descriptionList | order
  //  tableCall
  let path = `/unionpos?apiName=${apiName}`;
  if(conf.debug) path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};

export const unionposCopyImage = async ({ body }) => {
  const path = "/unionpos/image-copy";
  return await axiosCall({ method: "POST", path, body });
};

/* **********************************************************
  * smile pos apiname
    - order | orderAdd | viewOrders | isMenuUpdated | updatedMenu | tableList
  * STORE_ID
    - shopInfo.storeCode
  * paths
    - order:          '/partner/v1/table/order/new',  // 신규주문
    - orderAdd:       '/partner/v1/table/order/add',  // 추가주문
    - viewOrders:     '/partner/v1/table/order',      // 실시간 주문조회
    - isMenuUpdated:  '/partner/v1/menu/state',       // 상품정보 변경 조회
    - updateMenu:     '/partner/v1/menu/edit',        // 변경 상품정보 조회 - 전체 메뉴가 리턴됨
    - tableList:      '/partner/v1/table',            // 테이블 목록 조회

************************************************************/

export const smileposProxy = async ({ apiName, body }) => {
  let path = `/pos/smilepos?apiName=${apiName}`;
  if (conf.debug) path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};

export const unionposProxy2 = async ({apiName, body, ver}) => {
  let path = `/pos/unionpos?apiName=${apiName}`;
  
  if(conf.debug || ver === 'v1-1') path += '&mode=debug';
  
  return await axiosCall({ method: "POST", path, body });
}

export const okposProxy = async ({apiName, body, ver}) => {
  let path = `/pos/okpos?apiName=${apiName}`;
  
  if(conf.debug || ver === 'v1-1') path += '&mode=debug';
  
  return await axiosCall({method: 'POST', path, body});
}

export const payDataCreate = ({ posName, shopId, ver, body }) => {
  let path = `/payment/pay-record?shopId=${shopId}&posName=${posName}&ver=${ver}`;
  
  return axiosCall({ method: "POST", path, body });
}

export const goToPayKis = ({ shopId, orderId }) => {
  let path = payServerUrl + "/kis/pay";
  path += `?shopId=${shopId}&orderId=${orderId}`;
  
  window.location.href = path;
};

export const notifyOrderToStore = ({ body }) => {
  const path = '/api/order-notify';
  
  return axiosCall({ method: 'POST', path, body });
};